import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearErrors } from '../store/session/actions';
import { ReactComponent as InfoIcon } from '../vu/svg/primary/blocked-icon.svg';

/**
 * Renders the error if any.
 * Uses error information stored in session.sessionError 
 */
export class ErrorDisplay extends React.Component {

    onClose() {
        this.props.clearErrors();
    }

    render() {
        let errorMessage;
        let sessionError = this.props.sessionError;

        if (sessionError && sessionError.error) {
            const error = sessionError.error;
            if (typeof error === 'string' || error instanceof String) {
                errorMessage = error;
            }
            else if (error instanceof Error) {
                errorMessage = error.message;
            }
            else {
                errorMessage = 'Unknown Error';
            }
        }

        return (
            errorMessage ?
                <section className='error'>
                    <InfoIcon />
                    <span className='title'></span>
                    <span className='message'>{errorMessage}</span>

                    <button className='close' title='Close' onClick={this.onClose.bind(this)}>✕</button>
                </section>
                : null
        )
    }
}

ErrorDisplay.propTypes = {
    sessionError: PropTypes.shape({
        error: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({})
        ])
    }),
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = state => state.session;
const mapDispatchToProps = {
    clearErrors
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorDisplay);