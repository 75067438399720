import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/npr-logo-color.svg';
import Labels from '../assets/labels.json';

/**
 * Renders the app header.  
 * Contains the logo and the name.  
 * Requires no props.
 * 
 * Usage:
 * ```js
 * <AppHeader />
 * ```
 */
class AppHeader extends Component {


    render() {
        return (
            <header className='appHeader'>
                <Link to='/'>
                    <img src={logo} className='NPRLogo' alt='NPR' />
                    {Labels.appName}
                </Link>
                <span className='version'>{process && process.env && process.env.REACT_APP_VERSION ? 'v' + process.env.REACT_APP_VERSION : ''}</span>
            </header>
        );
    }
}

export default AppHeader;