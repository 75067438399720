import {
    SET_EVENTS,
    ADD_EVENT,
    ADD_EVENTS,
    EDIT_EVENT,
    DELETE_EVENT,
    GENERATE_EVENTS_REQUEST,
    GENERATE_EVENTS_FAILURE,
    GENERATE_EVENTS_SUCCESS,
} from './types';


export const setEvents = events => ({
    type: SET_EVENTS,
    events,
});

export const addEvent = event => ({
    type: ADD_EVENT,
    event,
});


export const addEvents = events => ({
    type: ADD_EVENTS,
    events,
});


export const editEvent = event => ({
    type: EDIT_EVENT,
    event,
});


export const deleteEvent = event => ({
    type: DELETE_EVENT,
    event,
});


/////// Generate Events ////////

export const generateEventsRequest = sid => ({
    type: GENERATE_EVENTS_REQUEST,
    sessionId: sid
})

export const generateEventsFailure = error => ({
    type: GENERATE_EVENTS_FAILURE,
    error
})

export const generateEventsSuccess = events => ({
    type: GENERATE_EVENTS_SUCCESS,
    events
})

