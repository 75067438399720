import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import LoadingWave from './LoadingWave.jsx';
import { uploadFailure, requestMetadataFailure } from '../store/session/actions';


/**
 * Renders the feed readers component
 * It shows the list of items from the feed.
 * Each item provides a link to RAD editor.
 */
export class RssFeedReader extends React.Component {

    constructor(props) {
        super(props);
        this.state = { feedData: null, loadedData: {}, fromNPR: false }
    }

    /**
     * Read list of podcasts from feed
     * @param {string} url 
     */
    readFeed(url) {
        const config = {};

        this.setState({ fromNPR: /npr.org/.test(url) });

        axios.get(`/api/rssfeed/${url}`, config)
            .then((response) => {
                this.setState({ feedData: response.data })
            }).catch(error => {
                this.props.metadataFailure('Cannot read feed...');
            });
    }


    componentWillMount() {
        const pathParts = this.props.location.pathname && this.props.location.pathname.split('/');
        if (pathParts && pathParts.length > 0) {
            this.readFeed(pathParts[pathParts.length - 1]);
        }
    }

    /**
     * Format date to string
     * @param {*} date 
     */
    formatDate(date) {
        return '' + new Date(date);
    }


    /**
     * Create en encoded link
     * @param {string} feedLink 
     */
    createLink(feedLink) {
        return '/?url=' + encodeURIComponent(feedLink);
    }


    analyseAllOrStop() {
        if (this.state.feedData && !this.state.analyseAll) {
            this.setState({ analyseAll: true }, () => this.next())
        } else {
            this.setState({ analyseAll: false })
        }
    }


    /**
     * Analyse next episode
     * @param {*} guid 
     */
    next(guid) {
        if (this.state.analyseAll && this.state.feedData) {
            const guids = this.state.feedData.items.map(item => item.guid);
            const index = guid ? guids.findIndex(aGuid => aGuid === guid) : -1;

            if (index < guids.length - 1) {
                let nextItem = this.state.feedData.items[index + 1];

                let nextIndex = index + 1;
                while (nextItem && nextIndex < (guids.length - 1) && this.state.loadedData[nextItem.guid]) {
                    nextIndex++;
                    nextItem = this.state.feedData.items[nextIndex]
                }
                if (nextItem) {
                    this.analyse(nextItem.guid, nextItem.link);
                }
                else {
                    this.analyseAllOrStop();
                }
            }
        }
    }


    /**
     * Analyse episode's MP3 to see if it has RAD and XML
     * @param {string} guid 
     * @param {string} feedLink 
     */
    analyse(guid, feedLink) {
        const ctx = this;
        let loadedData = { loading: true };

        ctx.setState({
            loadedData: {
                ...this.state.loadedData,
                [guid]: loadedData
            }
        })

        // load in RAD using API, returns metadata, check if have events
        axios.post('/api/file/url', { url: feedLink })
            .then((response) => {
                const sid = response.data.data;
                const config = {
                    headers: { 'Authorization': 'Key ' + sid }
                }

                axios.get(`/api/file/metadata`, config)
                    .then(fetchMetadataResult => {

                        loadedData.radEvents = fetchMetadataResult &&
                            fetchMetadataResult.data &&
                            fetchMetadataResult.data.data &&
                            fetchMetadataResult.data.data.remoteAudioData &&
                            fetchMetadataResult.data.data.remoteAudioData.events &&
                            fetchMetadataResult.data.data.remoteAudioData.events.length

                        // call server to get xml
                        axios.get('/api/file/xml', config)
                            .then((response) => {
                                const xml = response.data && response.data.xml;
                                if (/<?xml/.test(xml)) {
                                    loadedData.xml = xml;
                                }
                                else {
                                    loadedData.xml = '';
                                }
                                loadedData.loading = false;

                                ctx.setState({
                                    loadedData: {
                                        ...this.state.loadedData,
                                        [guid]: loadedData
                                    }
                                })
                                this.next(guid);

                                // close session
                                axios.delete('/api/session', config);

                            }).catch(error => {
                                ctx.setState({
                                    loadedData: {
                                        ...this.state.loadedData,
                                        [guid]: undefined
                                    }
                                })
                                this.props.metadataFailure('Cannot get XML');
                                // close session
                                axios.delete('/api/session', config);
                                this.next(guid);
                            });
                    }).catch(err => {
                        ctx.setState({
                            loadedData: {
                                ...this.state.loadedData,
                                [guid]: undefined
                            }
                        })
                        this.props.metadataFailure('Cannot get RAD metadata');
                        // close session
                        axios.delete('/api/session', config);
                        this.next(guid);
                    })

            }).catch(error => {
                ctx.setState({
                    loadedData: {
                        ...this.state.loadedData,
                        [guid]: undefined
                    }
                })
                this.props.uploadFailure(error);
                this.next(guid);
            });
    }


    render() {
        const loadedItems = Object.keys(this.state.loadedData).map(data => data).length;
        const itemElements = this.state.feedData
            ?
            <ul className="card">
                {this.state.feedData.items.map((aFeedItem) =>
                    <li key={aFeedItem.guid}>
                        <h3>
                            <a href={this.createLink(aFeedItem.link)} title='Open in RAD Tag Editor'>{aFeedItem.title}</a>
                            {this.state.loadedData[aFeedItem.guid] && !this.state.loadedData[aFeedItem.guid].loading
                                ? <section>
                                    {this.state.loadedData[aFeedItem.guid].radEvents ? <span className='ok'>{'RAD Events:' + this.state.loadedData[aFeedItem.guid].radEvents}</span> : <span>No RAD Events</span>}
                                    {this.state.fromNPR ? this.state.loadedData[aFeedItem.guid].xml ? <span className='ok'>Has XML</span> : <span>No XML</span> : ''}
                                </section>
                                : ''
                            }
                            {!this.state.loadedData[aFeedItem.guid]
                                ? <button onClick={this.analyse.bind(this, aFeedItem.guid, aFeedItem.link)}>
                                    Analyze
                                  </button>
                                : ''
                            }

                            {this.state.loadedData[aFeedItem.guid] && this.state.loadedData[aFeedItem.guid].loading
                                ? <button><LoadingWave /></button> : ''
                            }
                        </h3>
                        <span>{this.formatDate(aFeedItem.pubDate)}</span>
                        <p>{aFeedItem.content}</p>
                        {this.state.loadedData[aFeedItem.guid] && this.state.loadedData[aFeedItem.guid].xml
                            ? <pre>{this.state.loadedData[aFeedItem.guid].xml}</pre> : ''
                        }
                    </li >
                )}
            </ul>
            : []

        return (
            <section className='dropzoneWrapper rssFeed'>
                <h2 style={{ "display": "flex", "justifyContent": "space-between" }} >
                    {this.state.feedData ? this.state.feedData.title + ' ( ' + loadedItems + ' / ' + this.state.feedData.items.length + ' )' : 'Reading feed...'}
                    {this.state.feedData ? <button onClick={this.analyseAllOrStop.bind(this)}>
                        {this.state.analyseAll ? 'Stop' : 'Analyze All'}
                    </button> : ''}
                </h2>
                {itemElements}
            </section >
        );
    }
}


RssFeedReader.propTypes = {
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    uploadFailure,
    metadataFailure: (msg) => dispatch(requestMetadataFailure(msg)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RssFeedReader);