import axios from 'axios';
import {
    generateEventsRequest,
    generateEventsFailure,
    generateEventsSuccess,
} from './actions';
import Event from '../events/Event';


export const generateEvents = (sid, interval = 5) => {
    return (dispatch, getState) => {
        dispatch(generateEventsRequest(sid));
        return axios.get(`/api/generate/events/${interval}`, {
            headers: { Authorization: "Key " + sid }
        }).then((response) => {
            const events = response.data;
            if (events && Array.isArray(events)) {
                const eventUiModels = events.map(aEventData => {
                    aEventData._generated = true;
                    return Event.parse(aEventData)
                });
                dispatch(generateEventsSuccess(eventUiModels));
            } else {
                dispatch(generateEventsSuccess([]));
            }
        }).catch(error => {
            console.log('Generate events ERROR', error);
            const errorMessage = (error.response && error.response.data) || error.message;
            dispatch(generateEventsFailure(`Failed to generate events. Reason: ${errorMessage}`));
        });
    }
}        