import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearErrors, downloadFailure, setInfo } from '../store/session/actions';
import { saveMetadata, downloadAudio, downloadMetadata, downloadJson } from '../store/session/api';
import Labels from '../assets/labels.json';
import LoadingWave from './LoadingWave';
import Select from 'react-select';
import utils from '../utils/utils';

// show info at random (25% of the time)
const SHOW_INFO_PERCENT = 25;

/**
 * Renders the main header.  
 * It shows the uploaded audio file name and several buttons.
 */
export class MainHeader extends React.Component {
    downloadOptions = [
        { label: 'Download as .csv', value: 'csv' },
        { label: 'Download as .json', value: 'json' }
    ]

    constructor(props, context) {
        super(props, context);
        this.state = { downloadingAudio: false, downloadingMetadata: false, downloadDisabled: false }
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ downloadDisabled: this.downloadDisabled(nextProps) });
    }


    componentDidMount() {
        this.setState({ downloadDisabled: this.downloadDisabled(this.props) });
    }


    /**
     * Downloads the audio file     
     */
    downloadAudio() {
        const randomNumber = this.getRandomInt(100);
        if (randomNumber <= SHOW_INFO_PERCENT) {
            this.props.setInfo(Labels.requestFeedback);
        }

        this.setState({ downloadingAudio: true });
        this.props.clearErrors();

        const sessionId = this.props.sessionId;
        const fileName = this.props.fileName;
        this.props.downloadAudio(sessionId, fileName).then(() => {
            this.setState({ downloadingAudio: false, downloadingMetadata: false });
        })
    }

    handleDownloadSelectEvent(selection) {
        const { value } = selection;
        if (value === "csv") {
            this.downloadMetadata();
        }
        else if (value === "json") {
            this.downloadJson();
        }
    }

    /**
     * Downloads the metadata file     
     */
    downloadMetadata() {
        this.setState({ downloadingMetadata: true });
        this.props.clearErrors();

        const sessionId = this.props.sessionId;
        const fileName = this.props.fileName + '.csv';
        this.props.downloadMetadata(sessionId, fileName).then(() => {
            this.setState({ downloadingAudio: false, downloadingMetadata: false });
        })
    }


    /**
    * Downloads the metadata file     
    */
    downloadJson() {
        this.setState({ downloadingMetadata: true });
        this.props.clearErrors();

        const sessionId = this.props.sessionId;
        const fileName = this.props.fileName + '.json';
        this.props.downloadJson(sessionId, fileName).then(() => {
            this.setState({ downloadingAudio: false, downloadingMetadata: false });
        })
    }


    /**
     * Determines if the download button is disabled or not.
     * It should be disabled if:
     * - there are no events but have tracking urls
     * - there are no tracking urls but no events
     * - at least one event, custom metadata or tracking urls is invalid
     * 
     * @param {object} props 
     */
    downloadDisabled(props) {
        const events = props.events;
        const trackingUrls = props.trackingUrls;
        const customMetadata = props.customMetadata;

        const haveEvents = events && events.length > 0;
        const haveTrackingUrls = trackingUrls && trackingUrls.length > 0;
        const allTrackingUrlsValid = trackingUrls.reduce((state, aTrackingUrl) => {
            return state && aTrackingUrl.valid
        }, true);
        const allCustomMetadataValid = customMetadata.reduce((state, aCustomMetadata) => {
            return state && aCustomMetadata.valid
        }, true);
        const allEventsValid = events.reduce((state, anEvent) => {
            return state && anEvent.valid
        }, true);

        // check if intervals are valid
        const intervalEvents = events.filter(ev => ev.intervalKey);
        const allIntervalsValid = intervalEvents.reduce((state, anEvent) => {
            return state && utils.isIntervalEventValid(anEvent, intervalEvents);
        }, true)

        const allValid = allTrackingUrlsValid && allCustomMetadataValid && allEventsValid && allIntervalsValid;

        return this.state.downloadingAudio ||
            this.state.downloadingMetadata ||
            !allValid ||
            (haveEvents && !haveTrackingUrls) ||
            (!haveEvents && haveTrackingUrls);
    }


    /**
     * Returns the content of the tooltip that is displayed for the download button.
     * If the button is disabled, the tooltip explains why the button is disabled,
     * otherwise is just says nothing
     */
    downloadTooltip() {
        const isDisabled = this.state.downloadDisabled;
        return isDisabled ? Labels.downloadDisabledTooltip : '';
    }


    /**
     * Redirects to upload metadata page
     */
    uploadMetadata() {
        const sessionId = this.props.sessionId;
        this.props.saveMetadata(sessionId).then(result => {
            const history = this.props.history;
            history.push(`/upload-metadata/${sessionId}`);
        });
    }


    render() {
        return (
            // when trying to load an old session url, redirect home
            <h2 className='mainHeader'>
                <span className='fileName' title={this.props.fileName}>{this.props.fileName}</span>
                <span className='headerButtons'>
                    <button className='primary downloadAudio'
                        onClick={this.downloadAudio.bind(this)}
                        title={this.downloadTooltip()}
                        disabled={this.state.downloadDisabled}>
                        {this.state.downloadingAudio ? <LoadingWave /> : ''}
                        {this.state.downloadingAudio ? Labels.downloadingAudioButton : Labels.downloadAudioButton}
                    </button>
                    <Select
                        placeholder={Labels.downloadMetadataButton}
                        className='dropdown secondary'
                        classNamePrefix='dropdown'
                        value={null}
                        isClearable={false}
                        isSearchable={false}
                        isDisabled={this.state.downloadDisabled}
                        onChange={this.handleDownloadSelectEvent.bind(this)}
                        options={this.downloadOptions}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff'
                            },
                        })}
                    />
                    <button className='secondary uploadMetadata'
                        onClick={this.uploadMetadata.bind(this)}>
                        {Labels.uploadMetadataButton}
                    </button>
                </span>
            </h2>
        )
    }
}


MainHeader.propTypes = {
    sessionId: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    events: PropTypes.array.isRequired,
    trackingUrls: PropTypes.array.isRequired,
    customMetadata: PropTypes.array.isRequired,
    clearErrors: PropTypes.func.isRequired,
    setInfo: PropTypes.func.isRequired,
    downloadFailure: PropTypes.func.isRequired,
    downloadAudio: PropTypes.func.isRequired,
    downloadMetadata: PropTypes.func.isRequired,
    downloadJson: PropTypes.func.isRequired,
    saveMetadata: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    events: state.events,
    trackingUrls: state.trackingUrls,
    customMetadata: state.customMetadata,
    sessionId: state.session.sessionId,
    fileName: state.session.fileName
});
export const mapDispatchToProps = (dispatch) => ({
    clearErrors,
    downloadFailure,
    setInfo: (message) => dispatch(setInfo(message)),
    saveMetadata: (sessionId) => dispatch(saveMetadata(sessionId)),
    downloadAudio: (sessionId, fileName) => dispatch(downloadAudio(sessionId, fileName)),
    downloadMetadata: (sessionId, fileName) => dispatch(downloadMetadata(sessionId, fileName)),
    downloadJson: (sessionId, fileName) => dispatch(downloadJson(sessionId, fileName))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader));