import deepFreeze from '../deepFreeze';
import uuid from 'uuid/v4';
import utils from '../../utils/utils';

const eventTimeRegex = /^\d{2}:([0-5][0-9]):([0-5][0-9])\.\d{3}$/;
const invalidCharsRegex = /[",`\\]/;

/**
 * Event data model
 * Contains functions that transforms events from raw JSON to UI models.
 * 
 * Raw JSON for events
 * ```js
 * [
 *   {
 *     eventTime: "00:02:12.110",
 *     label: "adStart",
 *     adId: "Uber bank",
 *     eventNum: 12
 *   },
 *   ...
 * ]
 * ```
 * 
 *  UI models for events
 * ```js
 * [
 *   {
 *     id: "1885b3e0-994a-49bd-afe1-bce8741ccd9f",
 *     eventTime: "00:02:12.110",
 *     label: "adStart",
 *     other: [ 
 *        {
 *          id: "aa729d21-e8c8-45d3-a1fb-da61b84b2d08",
 *          label: "adId",
 *          value: "Uber bank"
 *        },
 *         {
 *          id: "b5dcf3f8-cd76-44f7-966b-474ef716f74c",
 *          label: "eventNum",
 *          value: 12 
 *        }
 *     ]
 *   },
 *   ...
 * ]
 * ```
 * 
 */
export default class Event {

    static NUMBER_OF_ADS_IN_BREAK = "numberinBreak";

    /**
     * Creates a new event
     * @param {string} id 
     * @param {string} eventTime 
     * @param {string} label 
     * @param {array} otherProps 
     * @param {boolean} generated 
     * @param {string} intervalKey          
     */
    constructor(id,
        eventTime,
        label = '',
        otherProps = [],
        generated = false,
        intervalKey = null) {

        this.id = id;
        this.eventTime = eventTime;
        this.label = label;
        this.other = otherProps;
        this._generated = generated;
        this._intervalKey = intervalKey;

        deepFreeze(this);
    }


    get valid() {
        let valid = this.isEventTimeValid() && this.isLabelValid();

        const otherProps = this.other || [];
        const areAllCustomPropsValid = otherProps.reduce((result, aProp) => {
            let validLabel = this.isCustomPropertyLabelValid(aProp.label);
            let validValue = this.isCustomPropertyValueValid(aProp.value);
            return result && validLabel && validValue;
        }, true);
        return valid && areAllCustomPropsValid;
    }


    get intervalKey() {
        return this._intervalKey;
    }


    get generated() {
        return this._generated;
    }


    isEventTimeValid = () => {
        return !!(eventTimeRegex && eventTimeRegex.test(this.eventTime));
    }


    isLabelValid = () => {
        return !invalidCharsRegex.test(this.label);
    }


    isCustomPropertyLabelValid = (label) => {
        return !!(label &&
            label !== 'eventTime' &&
            label !== 'label' &&
            !invalidCharsRegex.test(label));
    }


    isCustomPropertyValueValid = (value) => {
        return !!(value && !invalidCharsRegex.test(value));
    }


    isInsert = () => {
        const hasNumberOfAdsInBreak = (this.other || []).filter(aProp => aProp.label === Event.NUMBER_OF_ADS_IN_BREAK)[0];
        return !this._intervalKey && !!hasNumberOfAdsInBreak;
    }


    static parse = (aEvent) => {
        if (!aEvent) {
            return new Event();
        }

        const id = uuid();
        const eventTime = aEvent.eventTime;
        const label = aEvent.label;
        const generated = aEvent._generated;
        const intervalKey = aEvent._intervalKey;

        const otherProps = { ...aEvent };
        delete otherProps.eventTime;
        delete otherProps.label;
        delete otherProps._generated;
        delete otherProps._intervalKey;

        const otherPropsWithKeys = Object.entries(otherProps).map(aEntry => {
            return {
                id: uuid(),
                label: aEntry[0],
                value: aEntry[1]
            }
        })
        return new Event(id, eventTime, label, otherPropsWithKeys, generated, intervalKey);
    }


    static serialize = (event) => {
        if (!event) {
            return {}
        }

        let eventData = {
            eventTime: event.eventTime
        }

        // label is optional
        if (event.label) {
            eventData.label = event.label;
        }

        if (event._generated) {
            eventData._generated = event._generated;
        }

        if (event._intervalKey) {
            eventData._intervalKey = event._intervalKey;
        }

        // other properties are optional
        if (event.other) {
            const otherPropeties = event.other.reduce((result, aProperty) => {
                return { ...result, [aProperty.label]: aProperty.value };

            }, {})

            eventData = { ...eventData, ...otherPropeties }
        }
        return eventData;
    }


    /**
     * Creates a pair of events for an interval.
     * Events are regular events, with an exception, they are linked through
     * the intervalKey property.     
     * @param {string} label 
     * @param {string} startTime 
     * @param {number} duration 
     * @param {Array} other
     */
    static createIntervalEvents(label = 'Interval', startTime = '00:00:00.000', duration = 15, other = []) {
        const intervalKey = uuid();

        const seconds = utils.convertEventTimeToSeconds(startTime);
        const endSeconds = seconds + duration;

        const startEvent = new Event(uuid(), startTime, label + 'Start', other, false, intervalKey + "_start", true);
        const endEvent = new Event(uuid(), utils.convertSecondsToEventTime(endSeconds), label + 'End', other, false, intervalKey + "_end");

        return { startEvent, endEvent }
    }
}