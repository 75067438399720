import React from 'react';
import PropTypes from 'prop-types';
import Labels from '../assets/labels.json';
import { connect } from 'react-redux';
import axios from 'axios';
import { saveMetadata } from '../store/session/api';


/**
 * Renders the XML viewer 
 */
export class XMLViewer extends React.Component {


    constructor(props) {
        super(props);
        this.state = { xml: '' }
    }


    componentDidMount() {
        const sessionId = this.props.sessionId;
        this.setState({ xml: 'Loading...' })
        this.props.saveMetadata(sessionId).then(saveResult => {
            const config = {
                headers: {
                    Authorization: "Key " + this.props.sessionId
                }
            }

            // call server to upload the file
            axios.get('/api/file/xml', config)
                .then((response) => {
                    const xml = response.data && response.data.xml;
                    this.setState({ xml: xml || '' })
                }).catch(error => {
                    this.onError(error);
                });
        });
    }


    onError(error) {
        this.setState({ xml: 'Error loading XML' })
    }


    /**
    * Handle editor close
    */
    onClose() {
        this.props.editorClose();
    }

    render() {
        return (
            <section className='eventEditor xmlViewer card' >
                <header>XML viewer<button className='close' title={Labels.closeButton} onClick={this.onClose.bind(this)}>✕</button></header>

                <div className='eventEditorContent'>
                    <pre>
                        {this.state.xml}
                    </pre>
                </div>
            </section>
        )
    }
}


XMLViewer.propTypes = {
    sessionId: PropTypes.string.isRequired,
    editorClose: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    sessionId: state.session.sessionId
});
const mapDispatchToProps = { saveMetadata };
export default connect(mapStateToProps, mapDispatchToProps)(XMLViewer);