import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a text input  
 * 
 * Usage:
 * ```js
 * <ButtonGroup />
 * ```
 */
const ButtonGroup = (props) => (
    <div className={`buttonGroup eventLabel  ${(props.className || '')}`}>
        {props.label || ''}
        <span>
            {(props.options || []).map(anOption => {
                return <label key={anOption.value}>
                    <input type="radio"
                        checked={props.value === anOption.value}
                        onChange={props.onChange}
                        name={props.name}
                        value={anOption.value} />
                    {anOption.label}
                </label>
            })}
        </span>
    </div>
);


ButtonGroup.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired
}

export default ButtonGroup;