import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a loading indicator.
 * 
 * Usage:
 * ```js
 * <LoadingWave />
 * ```
 */
const LoadingWave = (props) => (
    <div className="loading">
        <div className="spinner-wave">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        {props.loadingMessage}
    </div>
);

LoadingWave.propTypes = {
    loadingMessage: PropTypes.string
}

export default LoadingWave;