import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import DropZoneWrapper from './components/DropZoneWrapper';
import MetadataUpload from './components/MetadataUpload';
import RssFeedReader from './components/RssFeedReader';
import Main from './components/Main';
import './App.css';
import AppHeader from './components/AppHeader';
import ErrorDisplay from './components/ErrorDisplay';
import InfoDisplay from './components/InfoDisplay';
import { Provider } from 'react-redux';
import store from './store/store';




const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <InfoDisplay />
        <ErrorDisplay />
        <AppHeader />
        <Route exact path="/" component={DropZoneWrapper} />
        <Route exact path="/upload-metadata/:sid" component={MetadataUpload} />
        <Route exact path="/rssfeed/:pid" component={RssFeedReader} />
        <Route exact path="/:sid" component={Main} />
      </div>
    </BrowserRouter>
  </Provider>
);

export default App;
