/**
 * Converts a time string to a number of seconds.
 * The expected time string format is hh:mm:ss.SSS
 * @param {string} eventTime The time to convert
 * @return {float} Number of seconds
 */
function convertEventTimeToSeconds(eventTime = '') {
    const parts = eventTime.split(':');
    const hourSeconds = parseInt(parts[0], 10) * 3600 || 0;
    const minuteSeconds = parseInt(parts[1], 10) * 60 || 0;
    const seconds = parseInt((parts[2] || '').split('.')[0], 10) || 0;
    const millis = parseInt((parts[2] || '').split('.')[1], 10) || 0;

    return hourSeconds + minuteSeconds + seconds + millis / 1000;
}


/**
 * Normalize a number of seconds (e.g 100.234) to 
 * a string e.g. '00:12:34.234'
 * @param {float} data 
 * @return {string} The time string
 */
function convertSecondsToEventTime(data) {
    let progress = parseFloat(data).toFixed(3);

    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let millis = 0;

    // find hours
    if (progress >= 3600) {
        hours = parseInt(progress / 3600, 10);
        progress -= hours * 3600;
    }

    //find minutes
    if (progress >= 60) {
        minutes = parseInt(progress / 60, 10);
        progress -= minutes * 60;
    }
    seconds = parseInt(progress, 10);
    millis = Math.round((progress - seconds) * 1000);

    const hoursString = hours < 10 ? '0' + hours : hours;
    const minutesString = minutes < 10 ? '0' + minutes : minutes;
    const secondsString = seconds < 10 ? '0' + seconds : seconds;
    let millisString = '' + millis;
    if (millisString.length < 3) {
        millisString = '0' + millisString;
    }
    if (millisString.length < 3) {
        millisString = '0' + millisString;
    }

    return hoursString + ':' + minutesString + ':' + secondsString + '.' + millisString;
}


const SESSION_ITEM_KEY = 'sessionState';

/**
 * Loads a previously saved session state
 * @return {(object | undefined)} Returns the session object or undefined.
 */
function loadSessionState() {
    try {
        const serializedState = sessionStorage.getItem(SESSION_ITEM_KEY);
        return JSON.parse(serializedState);
    }
    catch (err) {
        return undefined;
    }
}

/**
 * Saves the stringified version of the provided session state.
 * @param {*} state Session state object.
 */
function saveSessionState(state) {
    try {
        delete state.selectedRowId;
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem(SESSION_ITEM_KEY, serializedState);
    }
    catch (err) {
        console.log('Cannot save session data');
    }
}

/**
 * Returns the start and end event for an interval if the interval key is provided,
 * otherwise returns an array with {startEvent, endEvent} pairs for each interval
 * @param {*} events 
 * @param {*} intervalKey 
 */
function getIntervalEvents(events, intervalKey) {
    events = events || [];
    if (intervalKey) {
        const prefix = intervalKey.split('_')[0];
        const intervalEvents = events.filter(aEvent => aEvent.intervalKey && aEvent.intervalKey.startsWith(prefix));

        let startEvent, endEvent;
        if (intervalEvents && intervalEvents.length === 2) {
            const firstKey = intervalEvents[0].intervalKey;
            if (firstKey && firstKey.endsWith('_start')) {
                startEvent = intervalEvents[0];
                endEvent = intervalEvents[1];
            }
            else {
                startEvent = intervalEvents[1];
                endEvent = intervalEvents[0];
            }
        }

        return { startEvent, endEvent }
    }
    else {
        const intervalStartEvents = events.filter(aEvent => aEvent.intervalKey && aEvent.intervalKey.endsWith && aEvent.intervalKey.endsWith('_start'));
        return intervalStartEvents.map(aStartEvent => getIntervalEvents(events, aStartEvent.intervalKey));
    }
}


/**
 * Checks if an end event is valid by comparing its eventTime with
 * the start event eventTime.
 * 
 * If the event is not an end event, the event.valid is returned.
 * 
 * @param {*} event 
 * @param {*} events 
 */
function isIntervalEventValid(event, events = []) {
    if (event) {
        let valid = event.valid;
        if (event.intervalKey && event.intervalKey.endsWith && event.intervalKey.endsWith('_end')) {
            const prefix = event.intervalKey.split('_')[0];
            const startEventKey = prefix + '_start';
            const startEvent = events.find(aEvent => aEvent.intervalKey === startEventKey);
            if (startEvent && startEvent.eventTime > event.eventTime) {
                valid = false;
            }
        }
        return valid;
    }
    else return false;
}


export default {
    convertEventTimeToSeconds,
    convertSecondsToEventTime,
    loadSessionState,
    saveSessionState,
    getIntervalEvents,
    isIntervalEventValid,
    SESSION_ITEM_KEY
}