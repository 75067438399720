import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import Event from '../store/events/Event';
import { addEvent, editEvent } from '../store/events/actions';
import { selectRowId, setFileDuration } from '../store/session/actions';
import { ReactComponent as PlayIcon } from '../vu/svg/primary/play-icon.svg';
import { ReactComponent as PauseIcon } from '../assets/pause-icon.svg';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import Labels from '../assets/labels.json';
import LoadingWave from './LoadingWave';
import utils from '../utils/utils';

const MAX_VISIBLE_EVENTS = 1000;

/**
 * Renders the Waveform section.  
 * 
 * Usage:
 * ```js
 * <Waveform />
 * ```
 */
export class Waveform extends Component {

    wavesurfer;
    updatingEvent = false;

    constructor(props) {
        super(props);

        const intervalData = (this.props.configurationEvents || []).filter(aConf => aConf.key === 'interval')[0];

        this.state = {
            loading: false,
            error: null,
            progress: '00:00:00.000',
            duration: '',
            playing: false,
            tooManyEvents: false,
            intervalData,
            addIntervalButtonLabel: intervalData ? 'Add ' + intervalData.label + ' at' : ''
        };
    }


    /**
     * Create regions from events.
     * Each manually added event is rendered as a waveform-region.
     * Generated events are not rendered individually, instead a bar of dots is rendered.
     * 
     * @param {*} events 
     */
    createRegionsFromEvents(events = []) {
        // remove generated events 
        const customEvents = events.filter(aEvent => !aEvent.generated);

        // use only visible events
        const visibleEvents = customEvents.filter(aEvent => aEvent.eventTime <= this.state.duration);
        if (visibleEvents.length > MAX_VISIBLE_EVENTS) {
            this.setState({ loading: false, tooManyEvents: true });
            return;
        }

        this.setState({ loading: false, tooManyEvents: false });
        const mapVisibleEventsToIds = visibleEvents.reduce((result, aEvent) => {
            result[aEvent.id] = aEvent;
            return result;
        }, {})

        let allExistingRegions = this.wavesurfer && this.wavesurfer.regions ? this.wavesurfer.regions.list : {};
        // remove regions for removed events
        const regionsToRemove = Object.keys(allExistingRegions).filter(aRegionKey => {
            return !mapVisibleEventsToIds[aRegionKey];
        })
        regionsToRemove.forEach(aRegionKey => {
            allExistingRegions[aRegionKey].remove();
        })

        // get regions after delete and update them
        allExistingRegions = this.wavesurfer && this.wavesurfer.regions ? this.wavesurfer.regions.list : {};
        Object.keys(allExistingRegions).forEach(aRegionKey => {
            const region = allExistingRegions[aRegionKey];
            const event = mapVisibleEventsToIds[aRegionKey] || {};

            if (region.data.valid !== event.valid ||
                region.data.eventTime !== event.eventTime) {

                region.start = utils.convertEventTimeToSeconds(event.eventTime)
                region.end = region.start + 0.01;

                // update region data    
                region.data.eventTime = event.eventTime;
                region.data.valid = event.valid;

                region.remove();

                const regionOnWaveform = this.wavesurfer.addRegion(region);
                if (!event.valid) {
                    regionOnWaveform.element.classList.add('invalid');
                }
            }
        })

        let newEvents = visibleEvents.filter(aEvent => !allExistingRegions[aEvent.id]);
        const nonIntervalEvents = newEvents.filter(aEvent => !aEvent.intervalKey);
        if (nonIntervalEvents.length > 0) {
            // create new regions for new events
            const regions = nonIntervalEvents.map(aEvent => {
                const sec = utils.convertEventTimeToSeconds(aEvent.eventTime);
                const region = {
                    id: aEvent.id,
                    start: sec,
                    end: sec + 0.01,
                    loop: false,
                    drag: true,
                    resize: false,
                }
                return region;
            });

            // add events 
            regions.forEach((aRegion, index) => {
                const regionOnWaveform = this.wavesurfer.addRegion(aRegion);
                const event = events[index];
                if (regionOnWaveform && event) {
                    if (!event.valid) regionOnWaveform.element.classList.add('invalid');
                    regionOnWaveform.data.valid = event.valid;
                    regionOnWaveform.data.eventTime = event.eventTime;
                }
            });
        }

        // intervals
        const intervals = utils.getIntervalEvents(customEvents);
        if (intervals.length > 0) {
            const maxDuration = this.wavesurfer.getDuration();
            const regions = intervals.map(anInterval => {
                const { startEvent, endEvent } = anInterval;
                const start = utils.convertEventTimeToSeconds(startEvent.eventTime);
                const end = utils.convertEventTimeToSeconds(endEvent.eventTime);

                const region = {
                    id: startEvent.intervalKey,
                    start: start,
                    end: Math.min(end, maxDuration),
                    loop: false,
                    drag: true,
                    resize: false,
                }
                return region;
            });

            regions.forEach((aRegion, index) => {
                const regionOnWaveform = this.wavesurfer.addRegion(aRegion);
                regionOnWaveform.element.classList.add('interval');

                const event = events[index];
                if (regionOnWaveform && event) {
                    regionOnWaveform.data.eventTime = event.eventTime;
                }
            });
        }


        // if have generated events
        if (customEvents.length !== events.length) {
            const generatedEventsRegion = {
                id: 'generatedEventsRegion',
                start: 0,
                end: this.wavesurfer.getDuration(),
                loop: false,
                drag: false,
                resize: false,
            }
            const regionOnWaveform = this.wavesurfer.addRegion(generatedEventsRegion);
            regionOnWaveform.element.classList.add('generated');
        }
    }


    /**
     * Update state based on new props
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        if (!this.state.loading) {
            if (!this.updatingEvent) {
                this.createRegionsFromEvents(nextProps.events);
            }
            //find selected event time
            if (nextProps.selectedRowId) {
                const selectedEvent = nextProps.events.find(aEvent => aEvent.id === nextProps.selectedRowId);
                if (selectedEvent) {
                    const time = utils.convertEventTimeToSeconds(selectedEvent.eventTime);
                    this.wavesurfer.setCurrentTime(time);
                }
            }
        }
    }


    /**
     * Create the wavesurfer component
     */
    componentDidMount() {
        const sid = this.props.sessionId;
        this.setState({ loading: true });
        this.wavesurfer = this.wavesurfer || WaveSurfer.create({
            container: '#wave-form',
            responsive: true,
            partialRender: false,
            waveColor: '#ffb800',
            progressColor: '#adadad',
            cursorColor: '#0079d0',
            barGap: 1,
            barWidth: 3,
            xhr: {
                requestHeaders: [{
                    key: "Authorization",
                    value: "Key " + sid
                }
                ]
            },
            plugins: [
                TimelinePlugin.create({
                    container: '#wave-timeline'
                }),
                RegionsPlugin.create({
                })
            ]
        });

        this.wavesurfer.on('ready', () => {
            const duration = this.wavesurfer.getDuration();
            const normalizedDuration = utils.convertSecondsToEventTime(duration);
            this.props.setFileDuration(normalizedDuration);

            this.setState({ loading: false, duration: normalizedDuration });
            const events = this.props.events;
            this.createRegionsFromEvents(events);
        });

        this.wavesurfer.on('finish', () => {
            this.setState({ playing: false });
        });

        this.wavesurfer.on('seek', (seek) => {
            const seconds = this.wavesurfer.getCurrentTime();
            const normalizedProgress = utils.convertSecondsToEventTime(seconds);
            this.setState({ progress: normalizedProgress });
        });

        this.wavesurfer.on('error', (err) => {
            this.setState({ loading: false, error: err });
        });

        this.wavesurfer.on('audioprocess', (data) => {
            const normalizedProgress = utils.convertSecondsToEventTime(data);
            this.setState({ progress: normalizedProgress });
        })

        this.wavesurfer.on('region-click', (data) => {
            const id = data.id;
            const event = this.props.events.find(aEvent => aEvent.id === id);
            if (event && !event.generated) {
                this.props.selectRowId(event.id);
            }
        })

        this.wavesurfer.on('region-in', (data) => {
            const id = data.id;
            const event = this.props.events.find(aEvent => aEvent.id === id);
            if (event && !event.generated) {
                this.props.selectRowId(event.id);
            } else {
                const { startEvent, endEvent } = utils.getIntervalEvents(this.props.events, id);
                if (startEvent && endEvent) {
                    if (this.props.selectedRowId !== startEvent.id && this.props.selectedRowId !== endEvent.id) {
                        this.props.selectRowId(startEvent.id);
                    }
                }
            }
        })

        this.wavesurfer.on('region-out', (data) => {
            const id = data.id;
            const { startEvent, endEvent } = utils.getIntervalEvents(this.props.events, id);
            if (startEvent && endEvent) {
                this.props.selectRowId(endEvent.id);
            }
        })

        this.wavesurfer.on('region-updated', (data) => {
            this.updatingEvent = true;

            const id = data.id;
            const event = this.props.events.find(aEvent => aEvent.id === id);
            if (event && !event.generated) {
                const newEventTime = utils.convertSecondsToEventTime(data.start);
                const updatedEvent = new Event(event.id, newEventTime, event.label, event.other);
                this.props.editEvent(updatedEvent);
                this.props.selectRowId(event.id);
            }
            const { startEvent, endEvent } = utils.getIntervalEvents(this.props.events, id);
            if (startEvent && endEvent) {
                let newStartEventTime = utils.convertSecondsToEventTime(data.start);
                let newStartEvent = new Event(startEvent.id, newStartEventTime, startEvent.label, startEvent.other, false, startEvent.intervalKey)

                let newEndEventTime = utils.convertSecondsToEventTime(data.end);
                let newEndEvent = new Event(endEvent.id, newEndEventTime, endEvent.label, endEvent.other, false, endEvent.intervalKey)

                this.props.editEvent(newStartEvent);
                this.props.editEvent(newEndEvent);
                this.props.selectRowId(newStartEvent.id);
            }
        });

        this.wavesurfer.on('region-update-end', (data) => {
            this.updatingEvent = false;
        })

        this.wavesurfer.load(`/api/file`);
    }


    /**
     * Destroy the wavesurfer component
     */
    componentWillUnmount() {
        if (this.wavesurfer) {
            this.wavesurfer.stop();
            this.wavesurfer.unAll();
            this.wavesurfer.destroy();
        }
    }


    /**
     * Play/pause the audio
     */
    togglePlay() {
        if (this.wavesurfer) {
            this.wavesurfer.playPause();
            this.setState({ playing: this.wavesurfer.isPlaying() });
        }
    }


    /**
     * Add new event at the current time
     */
    addEvent() {
        const time = this.state.progress;
        const id = uuid();
        const newEvent = new Event(id, time);

        this.props.addEvent(newEvent);
        this.props.selectRowId(newEvent.id);
    }


    addInterval() {
        const intervalData = (this.props.configurationEvents || []).filter(aConf => aConf.key === 'interval')[0];
        if (intervalData) {
            const time = this.state.progress;
            const intervals = (this.props.events || []).filter(anEvent => anEvent.intervalKey);
            const noOfIntervals = Math.floor(intervals.length / 2);

            let labelSuffix = noOfIntervals + 1;
            const { label, durationInSeconds } = intervalData;
            const numberOfAds = Math.floor(durationInSeconds / 15);
            const mappedOtherProps = intervalData.other.map(aProp => {
                return { id: uuid(), label: aProp, value: aProp === Event.NUMBER_OF_ADS_IN_BREAK ? '' + numberOfAds : '' }
            })

            const { startEvent, endEvent } = Event.createIntervalEvents('event' + labelSuffix, time, durationInSeconds, mappedOtherProps);
            this.props.addEvent(startEvent);
            this.props.addEvent(endEvent);
            this.props.selectRowId(startEvent.id);
        }
    }


    render() {
        const loadingClassName = this.state.error ? 'audio-error' : this.state.loading ? 'loading' : '';
        return (
            <section className={`waveform card ${loadingClassName}`} >
                {this.state.error ?
                    <div className='waveform-error'>
                        <span>{this.state.error}</span> <br />
                        {Labels.browserCannotPlay} <br />
                        {Labels.continueWithoutWaveform}
                    </div>
                    :
                    ''}
                <div id="wave-controls">
                    {this.state.playing
                        ?
                        <button className='pause' onClick={this.togglePlay.bind(this)} title={Labels.pauseButton}>
                            <PauseIcon />
                            Pause
                        </button>
                        :
                        <button className='play' onClick={this.togglePlay.bind(this)} title={Labels.playButton}>
                            <PlayIcon />
                            Play
                        </button>
                    }

                    {this.state.tooManyEvents ?
                        <span className='warning'>Too many events to display</span>
                        : ''
                    }
                    <span>
                        {this.props.configurationEvents && this.props.configurationEvents.length > 0
                            ?
                            <button className='secondary addEventAt addIntervalAt' onClick={this.addInterval.bind(this)} title={Labels.addEventAtButton}>
                                {this.state.addIntervalButtonLabel || Labels.addIntervalAtButton}:
                            </button>
                            : ''}
                        <button className='secondary addEventAt' onClick={this.addEvent.bind(this)} title={Labels.addEventAtButton}>
                            {Labels.addEventAtButton}:
                        </button>
                        <em>{this.state.progress}</em> <em> / {this.state.duration}</em>
                    </span>
                </div>
                <div id="wave-form"></div>
                <div id="wave-timeline"></div>
                <LoadingWave />
            </section >
        );
    }
}


Waveform.propTypes = {
    sessionId: PropTypes.string.isRequired,
    selectedRowId: PropTypes.string,
    events: PropTypes.array.isRequired,
    addEvent: PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
    selectRowId: PropTypes.func.isRequired,
    setFileDuration: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    sessionId: state.session.sessionId,
    selectedRowId: state.session.selectedRowId,
    events: state.events,
    configurationEvents: state.session.configuration && state.session.configuration.events
});
const mapDispatchToProps = { addEvent, editEvent, selectRowId, setFileDuration };
export default connect(mapStateToProps, mapDispatchToProps)(Waveform);