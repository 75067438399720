import {
    SET_TRACKING_URLS,
    ADD_TRACKING_URL,
    EDIT_TRACKING_URL,
    DELETE_TRACKING_URL
} from './types';

export const setTrackingUrls = urls => ({
    type: SET_TRACKING_URLS,
    urls,
});


export const addTrackingUrl = url => ({
    type: ADD_TRACKING_URL,
    url
});


export const editTrackingUrl = url => ({
    type: EDIT_TRACKING_URL,
    url,
});


export const deleteTrackingUrl = url => ({
    type: DELETE_TRACKING_URL,
    url,
});