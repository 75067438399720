import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import saveMetadataAutomatically from './save-middleware';
import reducers from './reducers';

const DEBUG = false;

const middleware = [
    thunk,
    saveMetadataAutomatically,
    DEBUG && logger,
].filter(Boolean);

const store = createStore(
    reducers,
    applyMiddleware(...middleware)
);

export default store;