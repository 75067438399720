import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import ButtonGroup from './ButtonGroup';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import utils from '../utils/utils';
import Event from '../store/events/Event';
import Labels from '../assets/labels.json';
import { ReactComponent as AddIcon } from '../vu/svg/primary/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../vu/svg/primary/close-icon.svg';


const MAX_EVENT_PROPERTIES = 50;

/**
 * Renders the Interval Event editor
 * 
 * We use two events here, one for the interval start and one for the end.
 * Both events have exactly the same properties with three exceptions:
 * - id
 * - time
 * - label (label is partially the same, it ends with Start for start event
 * and with End for end event. e.g. "Ad Spot 1 Start" & "Ad Stop 1 End")
 */
export class IntervalEditor extends React.Component {

    constructor(props) {
        super(props);

        const intervalData = (this.props.configurationEvents || []).filter(aConf => aConf.key === 'interval')[0];
        const { startEvent, endEvent } = this.getEventsFromProps(props);
        this.state = { startEvent, endEvent, focusId: null, title: intervalData ? intervalData.label + ' Details' : '' }
    }

    getEventsFromProps(props) {
        const startEvent = props.events && props.events[0];
        const endEvent = props.events && props.events[1];
        return { startEvent, endEvent }
    }


    /**
     * Update state on props update
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        const { startEvent, endEvent } = this.getEventsFromProps(nextProps);
        this.setState({ startEvent, endEvent })
    }


    /**
     * Save event if another Event is about to be displayed in the editor
     */
    componentDidUpdate() {
        const startEvent = this.state.startEvent;
        const endEvent = this.state.endEvent;

        if (startEvent && this.props.events && startEvent !== this.props.events[0]) {
            this.props.saveEvent(startEvent);
        }
        if (endEvent && this.props.events && endEvent !== this.props.events[1]) {
            this.props.saveEvent(endEvent);
        }
    }


    /**
     * Handle editor close
     */
    onClose() {
        this.props.editorClose();
    }


    /**
     * Add a new custom property to the Event
     */
    addCustomEventProperty() {
        const newEventProperty = {
            id: uuid(),
            label: '',
            value: ''
        }

        const startEvent = this.state.startEvent;
        const endEvent = this.state.endEvent;

        const otherProps = startEvent.other || [];
        const newMetadataEventProps = [...otherProps, newEventProperty];

        const newStartEvent = new Event(startEvent.id, startEvent.eventTime, startEvent.label, newMetadataEventProps, false, startEvent.intervalKey);
        const newEndEvent = new Event(endEvent.id, endEvent.eventTime, endEvent.label, newMetadataEventProps, false, endEvent.intervalKey);
        this.setState({ startEvent: newStartEvent, endEvent: newEndEvent })
    }


    /**
      * Returns true when events cannot be added,
      * for example because the number of events exceeds
      * the maximum number of events.
      * 
      * @returns {boolean}
      */
    isAddDisabled() {
        const eventProps = this.state.startEvent && this.state.startEvent.other;
        return (eventProps || []).length >= MAX_EVENT_PROPERTIES;
    }


    /**
     * Remove custom property from Event
     * @param {*} aEventProperty 
     */
    removeCustomEventProperty(aEventProperty) {
        const startEvent = this.state.startEvent;
        const endEvent = this.state.endEvent;

        const currentMetadataEventProps = startEvent.other;
        const index = currentMetadataEventProps.findIndex(aProp => aProp.id === aEventProperty.id);
        const newMetadataEventProps = [
            ...currentMetadataEventProps.slice(0, index),
            ...currentMetadataEventProps.slice(index + 1)
        ]

        const newStartEvent = new Event(startEvent.id, startEvent.eventTime, startEvent.label, newMetadataEventProps, false, startEvent.intervalKey);
        const newEndEvent = new Event(endEvent.id, endEvent.eventTime, endEvent.label, newMetadataEventProps, false, endEvent.intervalKey);
        this.setState({ startEvent: newStartEvent, endEvent: newEndEvent })
    }


    /**
     * Update event with the new event time
     * @param {*} newEventTime 
     * @param {*} event 
     */
    onStartEventTimeChanged(newEventTime, event) {
        const oldStartTime = this.state.startEvent.eventTime;
        const oldEndTime = this.state.endEvent.eventTime;

        const diff = Math.max(0, utils.convertEventTimeToSeconds(oldEndTime) - utils.convertEventTimeToSeconds(oldStartTime));
        const newTimeInSecond = utils.convertEventTimeToSeconds(newEventTime)
        const newEndTime = utils.convertSecondsToEventTime(newTimeInSecond + diff);
        const endEvent = this.state.endEvent;
        const newEndEvent = new Event(endEvent.id, newEndTime, endEvent.label, endEvent.other, false, endEvent.intervalKey);

        const newStartEvent = new Event(event.id, newEventTime, event.label, event.other, false, event.intervalKey);
        this.setState({ startEvent: newStartEvent, endEvent: newEndEvent });
    }


    /**
     * Update event with the new event time
     * @param {*} newEventTime 
     * @param {*} event 
     */
    onEndEventTimeChanged(newEventTime, event) {
        const newEvent = new Event(event.id, newEventTime, event.label, event.other, false, event.intervalKey);
        this.setState({ endEvent: newEvent });
    }


    /**
     * Update events with the new label
     * @param {*} newLabel 
     * @param {*} event 
     */
    onStartEventLabelChanged(newLabel, event) {
        const newEvent = new Event(event.id, event.eventTime, newLabel, event.other, false, event.intervalKey);
        this.setState({ startEvent: newEvent });
    }


    /**
     * Update events with the new label
     * @param {*} newLabel 
     * @param {*} event 
     */
    onEndEventLabelChanged(newLabel, event) {
        const newEvent = new Event(event.id, event.eventTime, newLabel, event.other, false, event.intervalKey);
        this.setState({ endEvent: newEvent });
    }


    /**
     * Update event with the changed custom property
     * @param {*} newProps 
     * @param {*} event 
     */
    onCustomEventPropertyChanged(newProps) {
        const startEvent = this.state.startEvent;
        const endEvent = this.state.endEvent;

        const newStartEvent = new Event(startEvent.id, startEvent.eventTime, startEvent.label, newProps, false, startEvent.intervalKey);
        const newEndEvent = new Event(endEvent.id, endEvent.eventTime, endEvent.label, newProps, false, endEvent.intervalKey);
        this.setState({ startEvent: newStartEvent, endEvent: newEndEvent })
    }


    render() {
        const startEvent = this.state.startEvent || new Event();
        const endEvent = this.state.endEvent || new Event();

        // Start Label
        const startEventLabelProps = {
            id: startEvent.id + '_eventLabel',
            maxlength: '128',
            className: 'eventLabel',
            label: Labels.intervalStartLabel,
            text: startEvent['label'] || '',
            isValid: () => startEvent.isLabelValid(),
            invalidMessage: Labels.eventLabelInvalid,
            onTextChange: (event) => {
                const newLabel = event.target.value;
                this.onStartEventLabelChanged(newLabel, startEvent)
            }
        }

        // End Label
        const endEventLabelProps = {
            id: endEvent.id + '_eventLabel',
            maxlength: '128',
            className: 'eventLabel',
            label: Labels.intervalEndLabel,
            text: endEvent['label'] || '',
            isValid: () => endEvent.isLabelValid(),
            invalidMessage: Labels.eventLabelInvalid,
            onTextChange: (event) => {
                const newLabel = event.target.value;
                this.onEndEventLabelChanged(newLabel, endEvent)
            }
        }

        // Start Event time 
        const startEventTimeProps = {
            id: startEvent.id + '_eventTime',
            maxlength: '128',
            className: 'eventLabel',
            label: Labels.startEventTime,
            text: startEvent['eventTime'] || '',
            invalidMessage: Labels.eventTimeInvalid,
            isValid: () => startEvent.isEventTimeValid(),
            onTextChange: (event) => {
                const newTime = event.target.value;
                this.onStartEventTimeChanged(newTime, startEvent);
            }
        }


        // End Event time 
        const endEventTimeProps = {
            id: endEvent.id + '_eventTime',
            maxlength: '128',
            className: 'eventLabel',
            label: Labels.endEventTime,
            text: endEvent['eventTime'] || '',
            invalidMessage: Labels.endEventTimeInvalid,
            isValid: () => endEvent.isEventTimeValid() && endEvent.eventTime > startEvent.eventTime,
            onTextChange: (event) => {
                const newTime = event.target.value;
                this.onEndEventTimeChanged(newTime, endEvent);
            }
        }

        const startEventCustomProperties = (startEvent.other || []);

        // Special handle of numberOfAdsInBreak
        let numberOfAdsInBreakProps = null;
        const numberOfAdsInBreak = startEventCustomProperties.find(aProp => aProp.label === Event.NUMBER_OF_ADS_IN_BREAK);
        if (numberOfAdsInBreak) {
            numberOfAdsInBreakProps = {
                label: 'Number of Slots in Break',
                name: Event.NUMBER_OF_ADS_IN_BREAK,
                value: numberOfAdsInBreak.value,
                options: [
                    { label: 'Not set', value: 'notSet' },
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' }
                ],
                onChange: (event) => {
                    const newNumberOfAds = event.target.value;
                    const newNumberOfAdsProp = { ...numberOfAdsInBreak, value: newNumberOfAds };
                    const index = startEventCustomProperties.findIndex(aOption => aOption.id === newNumberOfAdsProp.id);

                    const newEventProps = [
                        ...startEventCustomProperties.slice(0, index),
                        newNumberOfAdsProp,
                        ...startEventCustomProperties.slice(index + 1)
                    ]
                    this.onCustomEventPropertyChanged(newEventProps);
                }
            }
        }

        // Rest of properties        
        const eventCustomPropertiesWithoutNumberOfAds = startEventCustomProperties.filter(aProp => aProp.label !== Event.NUMBER_OF_ADS_IN_BREAK);

        const eventProperties = eventCustomPropertiesWithoutNumberOfAds.map((aCustomEventProp, index) => {
            return {
                id: aCustomEventProp.id,
                labelProps: {
                    id: aCustomEventProp.id + '_label',
                    maxlength: '128',
                    className: 'event_propery_item',
                    text: aCustomEventProp.label,
                    autoFocus: this.state.focusId === aCustomEventProp.id,
                    invalidMessage: Labels.eventCustomPropertyLabelInvalid,
                    isValid: () => startEvent.isCustomPropertyLabelValid(aCustomEventProp.label),
                    onTextChange: (event) => {
                        const newPropLabel = event.target.value;
                        const newMetadataProp = { ...aCustomEventProp, label: newPropLabel };
                        const index = startEventCustomProperties.findIndex(aOption => aOption.id === aCustomEventProp.id);
                        const newEventProps = [
                            ...startEventCustomProperties.slice(0, index),
                            newMetadataProp,
                            ...startEventCustomProperties.slice(index + 1)
                        ]
                        this.onCustomEventPropertyChanged(newEventProps);
                    }
                },
                valueProps: {
                    id: aCustomEventProp.id + '_value',
                    maxlength: '128',
                    className: 'event_propery_item',
                    text: aCustomEventProp.value,
                    invalidMessage: Labels.eventCustomPropertyValueInvalid,
                    isValid: () => startEvent.isCustomPropertyValueValid(aCustomEventProp.value),
                    onTextChange: (event) => {
                        const newPropValue = event.target.value;
                        const newProp = { ...aCustomEventProp, value: newPropValue };
                        const index = startEventCustomProperties.findIndex(aOption => aOption.id === aCustomEventProp.id);
                        const newEventProps = [
                            ...startEventCustomProperties.slice(0, index),
                            newProp,
                            ...startEventCustomProperties.slice(index + 1)
                        ]
                        this.onCustomEventPropertyChanged(newEventProps);
                    }
                }
            }
        });

        const elements = eventProperties.map((aProp) =>
            <li key={aProp.id}>
                <TextInput {...aProp.labelProps} />
                <span>:</span>
                <TextInput {...aProp.valueProps} />
                <button className='delete eventPropertyDelete' title={Labels.deleteButton} onClick={this.removeCustomEventProperty.bind(this, aProp)}>
                    <DeleteIcon />
                    Remove Event Property
                </button>
            </li>
        );

        return (
            <section className='eventEditor intervalEditor card' >
                <header>{this.state.title || Labels.intervalEditorTitle} <button className='close' title={Labels.closeButton} onClick={this.onClose.bind(this)}>✕</button></header>

                <div className='eventEditorContent'>
                    <div className='eventTimeLabel'>
                        <TextInput {...startEventTimeProps} />
                        <TextInput {...endEventTimeProps} />
                    </div>
                    <div className='eventTimeLabel'>
                        <TextInput {...startEventLabelProps} />
                        <TextInput {...endEventLabelProps} />
                    </div>
                    {numberOfAdsInBreakProps ?
                        <div className='eventTimeLabel numberOfAds'>
                            <ButtonGroup {...numberOfAdsInBreakProps} />
                        </div>
                        : ''
                    }
                    <h4>{Labels.eventEditorOtherTitle}
                        <button className='add eventPropertyAdd' title={this.isAddDisabled() ? Labels.maxEventDetails : Labels.addButton}
                            onClick={this.addCustomEventProperty.bind(this)}
                            disabled={this.isAddDisabled()}>
                            <AddIcon />
                            Add Event Property
                        </button>
                    </h4>
                    <ul>
                        {elements}
                    </ul>
                </div>
            </section>
        );
    }
}

IntervalEditor.propTypes = {
    event: PropTypes.instanceOf(Event),
    editorClose: PropTypes.func.isRequired,
    saveEvent: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    configurationEvents: state.session.configuration && state.session.configuration.events
});
export default connect(mapStateToProps, null)(IntervalEditor);