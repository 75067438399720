import axios from 'axios';

import Event from '../events/Event';
import CustomMetadata from '../customMetadata/CustomMetadata';
import TrackingUrl from '../trackingUrls/TrackingUrl';
import Labels from '../../assets/labels.json';

import {
    requestMetadata,
    requestMetadataFailure,
    requestMetadataSuccess,
    saveMetadataRequest,
    saveMetadataFailure,
    saveMetadataSuccess,
    downloadFailure,
    downloadSuccess,
    requestConfiguration,
    requestConfigurationFailure,
    requestConfigurationSuccess,
    setFileDuration,
    generateEventsFromXML,
    generateEventsFromXMLFailure,
} from './actions';
import { setEvents, addEvents } from '../events/actions';
import { setCustomMetadata, addCustomMetadata } from '../customMetadata/actions';
import { setTrackingUrls, addTrackingUrl } from '../trackingUrls/actions';


export const fetchMetadata = sid => {
    return (dispatch) => {
        dispatch(requestMetadata(sid));
        return axios.get(`/api/file/metadata`, {
            headers: { Authorization: "Key " + sid }
        }).then((response) => {
            const error = response.data.error;
            if (error) {
                dispatch(requestMetadataFailure(error));
            } else {
                const metadata = response.data.data || {};
                dispatch(requestMetadataSuccess(metadata));

                const remoteAudioData = metadata.remoteAudioData || {};

                // tracking urls
                let trackingUrls = remoteAudioData.trackingUrls || [];
                trackingUrls = trackingUrls.filter((aUrl, index) => trackingUrls.indexOf(aUrl) === index);
                const trackingUrlUiModels = trackingUrls.map(aTrackingUrlData => {
                    return TrackingUrl.parse(aTrackingUrlData)
                });
                dispatch(setTrackingUrls(trackingUrlUiModels));

                // events
                const events = remoteAudioData.events || [];
                let eventUiModels = events.map(aEventData => {
                    return Event.parse(aEventData)
                });
                dispatch(setEvents(eventUiModels));

                // custom metadata    
                let customMetadata = { ...remoteAudioData };
                delete customMetadata.trackingUrls;
                delete customMetadata.events;

                const customMetadataUiModels = Object.entries(customMetadata).map(aEntry => {
                    return CustomMetadata.parse(aEntry)
                })
                dispatch(setCustomMetadata(customMetadataUiModels));

                let duration = response.data.duration || "";
                // should be 3 parts: hours, minutes and seconds
                const parts = duration.split(':');
                if (parts.length < 3) {
                    parts.unshift('00');
                }
                if (parts.length < 3) {
                    parts.unshift('00');
                }
                duration = parts.join(':');
                dispatch(setFileDuration(duration));
            }
        }).catch(error => {
            const errorMessage = (error.response && error.response.data) || error.message;
            dispatch(requestMetadataFailure(`${Labels.fetchMetadataFailed} ${errorMessage}`));
        });
    }
}


export const saveMetadata = (sid) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(saveMetadataRequest(sid));

        const customMetadata = state.customMetadata.reduce((result, aModel) => {
            const modelData = CustomMetadata.serialize(aModel)
            return { ...result, ...modelData }
        }, {});

        const metadata = {
            remoteAudioData: {
                trackingUrls: state.trackingUrls.map(aTrackingUrl => {
                    return TrackingUrl.serialize(aTrackingUrl)
                }),
                events: state.events.map(aEvent => {
                    return Event.serialize(aEvent)
                }),
                ...customMetadata
            }
        }

        return axios.put(`/api/file/metadata`, metadata, {
            headers: { Authorization: "Key " + sid, 'Content-Type': 'application/json' }
        }).then(res => {
            dispatch(saveMetadataSuccess(res));
        }).catch(err => {
            dispatch(saveMetadataFailure(err));
        })
    }
};


export const download = (url, sessionId, downloadedFileName, errorText) => {
    return dispatch => {
        return axios({
            method: 'GET',
            url: url,
            headers: { 'Authorization': 'Key ' + sessionId },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadedFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(downloadSuccess(downloadedFileName));
        }).catch((error) => {
            let errorMessage = error.message;
            dispatch(downloadFailure(`${errorText} ${errorMessage}`));
        })
    }
}


export const downloadAudio = (sessionId, fileName) => {
    return dispatch => {
        return dispatch(saveMetadata(sessionId)).then(saveResult => {
            return dispatch(download('/api/file', sessionId, fileName, Labels.downloadAudioFailed));
        });
    }
}


export const downloadMetadata = (sessionId, fileName) => {
    return dispatch => {
        return dispatch(saveMetadata(sessionId)).then(saveResult => {
            return dispatch(download('/api/metadata', sessionId, fileName, Labels.downloadMetadataFailed));
        });
    }
}


export const downloadJson = (sessionId, fileName) => {
    return dispatch => {
        return dispatch(saveMetadata(sessionId)).then(saveResult => {
            return dispatch(download('/api/metadata/json', sessionId, fileName, Labels.downloadMetadataFailed));
        });
    }
}


export const fetchConfiguration = sid => {
    return dispatch => {
        dispatch(requestConfiguration(sid));
        return axios.get(`/api/configuration`, {
            headers: { Authorization: "Key " + sid }
        }).then((response) => {
            const error = response.data.error;
            if (error) {
                dispatch(requestConfigurationFailure(error));
            } else {
                const configuration = response.data.data;
                dispatch(requestConfigurationSuccess(configuration));
            }
        }).catch(error => {
            const errorMessage = (error.response && error.response.data) || error.message;
            dispatch(requestConfigurationFailure(`${Labels.fetchConfigurationFileFailed} ${errorMessage}`));
        });
    }
}


export const fetchConfigurationAndMetadata = sid => {
    return (dispatch, getState) => {
        return dispatch(fetchConfiguration(sid)).then(saveResult => {
            return dispatch(fetchMetadata(sid)).then(metadata => {
                const state = getState();

                const configuration = { ...state.session.configuration };
                configuration.trackingUrls = configuration.trackingUrls || [];
                configuration.customMetadata = configuration.customMetadata || [];

                let trackingUrls = state.trackingUrls;
                configuration.trackingUrls.forEach(aUrl => {
                    const foundTrackingUrl = trackingUrls.find(aTrackingUrl => aTrackingUrl.url === aUrl);
                    if (!foundTrackingUrl) {
                        dispatch(addTrackingUrl(TrackingUrl.parse(aUrl)));
                    }
                })

                let customMetadata = state.customMetadata;
                configuration.customMetadata.forEach(aConfigCM => {
                    const foundCustomMetadata = customMetadata.find(aCustomMetadata => aCustomMetadata.label === aConfigCM);
                    if (!foundCustomMetadata) {
                        dispatch(addCustomMetadata(CustomMetadata.parse([aConfigCM, ""])))
                    }
                })
            })
        });
    }
}


export const fetchEventsGeneratedFromXML = sid => {
    return dispatch => {
        dispatch(generateEventsFromXML(sid));
        return axios.get(`/api/generate/xml/events`, {
            headers: { Authorization: "Key " + sid }
        }).then((response) => {
            const error = response && response.data && response.data.error;
            if (error) {
                dispatch(generateEventsFromXMLFailure(error));
            } else {
                const xmlEvents = (response && response.data && response.data.xmlEvents) || [];
                const parsedEvents = xmlEvents.map(aEventData => {
                    return Event.parse(aEventData)
                });
                dispatch(addEvents(parsedEvents));
                return parsedEvents;
            }
        }).catch(error => {
            const errorMessage = (error && error.response && error.response.data) || error.message;
            dispatch(generateEventsFromXMLFailure(`${Labels.fetchConfigurationFileFailed} ${errorMessage}`));

            return Promise.reject(errorMessage);
        });
    }
}



export const ping = sid => {
    return dispatch => {
        return axios.get(`/api/session`, {
            headers: { Authorization: "Key " + sid }
        }).then((response) => {
        }).catch(error => { });
    }
}

