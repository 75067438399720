import { combineReducers } from 'redux';

import session from './session/reducers';
import events from './events/reducers';
import customMetadata from './customMetadata/reducers';
import trackingUrls from './trackingUrls/reducers';

export default combineReducers({
    session,
    events,
    customMetadata,
    trackingUrls
});