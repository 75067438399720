import { saveMetadata } from './session/api';
let timer;

/**
 * Saves metadata automatically after a add, edit or delete
 * action was triggered.
 */
const saveMetadataAutomatically = store => next => action => {
    let result = next(action);

    // only save if a add, edit or delete action was triggered
    if (/(add|edit|delete)/gi.test(action.type)) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            const dispatch = store.dispatch;
            const getState = store.getState;
            const sid = getState().session.sessionId;

            dispatch(saveMetadata(sid));
        }, 1000);
    }
    return result
}

export default saveMetadataAutomatically;