import uuid from 'uuid/v4';

const invalidCharsRegex = /[",`\\]/;

/**
 * Custom metadata data model
 * Contains functions that transforms custom metadata from raw JSON to UI models.
 * 
 * Raw JSON for custom metadata
 * ```js
 *  {
 *    episodeId: 1223232,
 *    podcastId: 434343
 *  }
 * ```
 * 
 *  UI models for custom metadata
 * ```js
 * [
 *   {
 *     id: "1885b3e0-994a-49bd-afe1-bce8741ccd9f",
 *     label: "episodeId",
 *     value: 1223232
 *   },
 *   {
 *     id: "e3e3acce-73ad-4138-914b-0fa7af92d5f3",
 *     label: "podcastId",
 *     value: 434343
 *   }
 * ]
 * ```
 */
export default class CustomMetadata {

    constructor(id, label, value) {
        this.id = id;
        this.label = label;
        this.value = value;

        Object.freeze(this);
    }


    get valid() {
        return !!(this.value &&
            this.label &&
            this.label !== 'events' &&
            this.label !== 'trackingUrls' &&
            !invalidCharsRegex.test(this.label) &&
            !invalidCharsRegex.test(this.value))
    }


    isLabelValid() {
        return !!(
            this.label &&
            this.label !== 'events' &&
            this.label !== 'trackingUrls' &&
            !invalidCharsRegex.test(this.label))
    }


    isValueValid() {
        return !!(this.value && !invalidCharsRegex.test(this.value))
    }


    static parse = (rawData = []) => {
        return new CustomMetadata(uuid(), rawData[0], rawData[1], true);
    }


    static serialize = (aCustomMetadata) => {
        return aCustomMetadata ? { [aCustomMetadata.label]: aCustomMetadata.value } : {};
    }
}