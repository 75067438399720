import React from 'react';
import PropTypes from 'prop-types';
import MainHeader from './MainHeader';
import Waveform from './Waveform';
import CustomMetadataWrapper from './CustomMetadataWrapper';
import TrackingUrls from './TrackingUrls';
import EventsWrapper from './EventsWrapper';
import Labels from '../assets/labels.json';
import { ReactComponent as InfoIcon } from '../vu/svg/primary/blocked-icon.svg';
import { connect } from 'react-redux';
import { clearErrors } from '../store/session/actions';
import { fetchMetadata, ping } from '../store/session/api';
import LoadingWave from './LoadingWave';


/**
 * Renders the entire application except the header.  
 * It is a container that has the following sections:
 * 
 *  Main Header
 *  Waveform
 *  Events
 *  Tracking Urls
 *  Custom Metadata 
 */
export class Main extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = { loaded: false }
    }


    componentDidMount() {
        const sid = this.props.sessionId;
        this.props.fetchMetadata(sid).then(result => {
            this.setState({ loaded: true });
        }).catch(err => {
            this.setState({ loaded: true });
        })

        // start ping timer
        this.pingTimer = setInterval(() => {
            this.props.ping(sid);
        }, 300000);
    }


    componentWillUnmount() {
        this.props.clearErrors();
        if (this.pingTimer) {
            clearTimeout(this.pingTimer);
        }
    }


    render() {
        return (
            this.state.loaded
                ?
                this.props.fetchError
                    ?
                    <div className="loading failed">
                        <InfoIcon />
                        {Labels.loadingMetadataFailed}
                        <a href="/">{Labels.uploadAnother}</a>
                    </div>
                    :
                    <main className="appMain">
                        <MainHeader />
                        <Waveform />
                        <EventsWrapper />
                        <TrackingUrls />
                        <CustomMetadataWrapper />
                    </main>
                :
                <LoadingWave loadingMessage={Labels.loadingMetadata} />

        )
    }
};

Main.propTypes = {
    sessionId: PropTypes.string.isRequired,
    fetchingMetadata: PropTypes.bool,
    fetchError: PropTypes.bool,
    fetchMetadata: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    ping: PropTypes.func
}

const mapStateToProps = (state) => ({
    sessionId: state.session.sessionId,
    fetchingMetadata: state.session.fetchingMetadata,
    fetchError: !!(state.session.sessionError && state.session.sessionError.type === 'fetchMetadata')
});
const mapDispatchToProps = {
    fetchMetadata,
    ping,
    clearErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);