import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Labels from '../assets/labels.json';
import { ReactComponent as AddIcon } from '../vu/svg/primary/add-icon.svg';
import { ReactComponent as EditIcon } from '../vu/svg/primary/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../vu/svg/primary/close-icon.svg';
import { ReactComponent as InfoIcon } from '../vu/svg/primary/blocked-icon.svg';


/**
 * Renders a Event
 * 
 * Usage:
 * ```js
 * <EventComponent />
 * ```
 */
class EventComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isAddDisabled: props.isAddDisabled,
            readonly: props._generated,
            id: props.id,
            maxEventTime: props.fileDuration,
            label: props.label,
            eventTime: props.eventTime,
            selected: props.selected,
            intervalKey: props.intervalKey,
            valid: props.valid
        };
    }


    /**
     * Update state on props change
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        this.setState({
            isAddDisabled: nextProps.isAddDisabled,
            readonly: nextProps._generated,
            id: nextProps.id,
            maxEventTime: nextProps.fileDuration,
            label: nextProps.label,
            eventTime: nextProps.eventTime,
            selected: nextProps.selected,
            intervalKey: nextProps.intervalKey,
            valid: nextProps.valid
        })
    }


    /**
     * Only update if something changed
     * @param {*} nextProps 
     * @param {*} nextState 
     */
    shouldComponentUpdate(nextProps, nextState) {
        const result = this.state.readonly !== nextState.readonly ||
            this.state.isAddDisabled !== nextState.isAddDisabled ||
            this.state.id !== nextState.id ||
            this.state.maxEventTime !== nextState.maxEventTime ||
            this.state.label !== nextState.label ||
            this.state.eventTime !== nextState.eventTime ||
            this.state.selected !== nextState.selected ||
            this.state.valid !== nextState.valid ||
            this.state.intervalKey !== nextState.intervalKey

        return result;
    }


    /**
     * Duplicate current Event
     * @param {*} event 
     */
    duplicateEvent(event) {
        this.props.duplicateRow(this.state.id);

        if (event) {
            event.stopPropagation();
        }
        return false;
    }


    /**
     * Edit current Event
     */
    editEvent() {
        this.props.selectEventRow(this.state.id);
    }


    /**
     * Removes current event
     * @param {*} event 
     */
    removeEvent(event) {
        this.props.deleteEvent({ id: this.state.id, label: this.state.label, eventTime: this.state.eventTime, intervalKey: this.state.intervalKey });

        // unselect the row if is selected
        if (this.state.selected) {
            this.props.selectEventRow(null);
        }

        if (event) {
            event.stopPropagation();
        }
        return false;
    }


    render() {
        let fullClassName = this.state.selected ? ' selected' : '';
        const isOverMax = this.state.maxEventTime && this.state.eventTime > this.state.maxEventTime;
        if (isOverMax) {
            fullClassName += ' overmax';
        }
        const valid = this.state.valid;
        if (!valid) {
            fullClassName += ' invalidEvent';
        }
        const readonly = this.state.readonly;
        if (readonly) {
            fullClassName += ' readonly';
        }
        const intervalKey = this.state.intervalKey;
        if (intervalKey) {
            fullClassName += ' interval';
        }

        return (
            <li className={fullClassName} onClick={readonly ? () => { } : this.editEvent.bind(this, true)}>
                <label className='positionTextInput'>
                    {isOverMax || !valid ?
                        <div data-for={this.state.id}
                            data-tip={valid ? Labels.eventTimeOverflow : Labels.eventInvalid}>
                            <InfoIcon />
                            <ReactTooltip
                                id={this.state.id}
                                place='top'
                                type={valid ? 'warning' : 'error'}
                                html={true}
                                effect='solid'
                                className='tooltip' />
                        </div>
                        : null}
                    {this.state.eventTime}
                </label>
                <label className='labelTextInput'>{this.state.label}</label>
                {/* Generated events are readonly and can only be deleted */}
                {readonly ?
                    <button className='delete eventDelete'
                        title={Labels.deleteButton}
                        onClick={this.removeEvent.bind(this)}>
                        <DeleteIcon />
                    </button>
                    :
                    <span className='buttons'>
                        <button className='add eventDuplicate'
                            title={Labels.duplicateButton}
                            disabled={this.state.isAddDisabled}
                            onClick={this.duplicateEvent.bind(this)}>
                            <AddIcon />
                            Duplicate Event
                        </button>
                        <button className='edit eventEdit'
                            title={Labels.editButton}
                            onClick={this.editEvent.bind(this, true)}>
                            <EditIcon />
                            Edit Event
                        </button>
                        <button className='delete eventDelete'
                            title={Labels.deleteButton}
                            onClick={this.removeEvent.bind(this)}>
                            <DeleteIcon />
                            Remove Event
                        </button>
                    </span>
                }
            </li >
        );
    };
};

EventComponent.propTypes = {
    id: PropTypes.string.isRequired,
    maxEventTime: PropTypes.string,
    label: PropTypes.string,
    eventTime: PropTypes.string,
    selected: PropTypes.bool,
    isAddDisabled: PropTypes.bool,
    readonly: PropTypes.bool,
    valid: PropTypes.bool,
    duplicateRow: PropTypes.func.isRequired,
    selectEventRow: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
}

export default EventComponent;