import React from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';
import ButtonGroup from './ButtonGroup';
import uuid from 'uuid/v4';
import Event from '../store/events/Event';
import Labels from '../assets/labels.json';
import { ReactComponent as AddIcon } from '../vu/svg/primary/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../vu/svg/primary/close-icon.svg';


const MAX_EVENT_PROPERTIES = 50;

/**
 * Renders the Insert editor
 */
export class InsertEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = { insertEvent: props.event, focusId: null }
    }

    /**
     * Update state on props update
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        this.setState({ insertEvent: nextProps.event });
    }


    /**
     * Save event if another Event is about to be displayed in the editor
     */
    componentDidUpdate() {
        const insertEvent = this.state.insertEvent;
        if (insertEvent && insertEvent !== this.props.event) {
            this.props.saveEvent(insertEvent);
        }
    }


    /**
     * Handle editor close
     */
    onClose() {
        this.props.editorClose();
    }


    /**
     * Add a new custom property to the Event
     */
    addCustomEventProperty() {
        const newEventProperty = {
            id: uuid(),
            label: '',
            value: ''
        }

        const event = this.state.insertEvent;
        const otherProps = event.other || [];
        const newInsertEventProps = [...otherProps, newEventProperty];
        const newEvent = new Event(event.id, event.eventTime, event.label, newInsertEventProps);
        this.setState({ insertEvent: newEvent });
    }


    /**
      * Returns true when events cannot be added,
      * for example because the number of events exceeds
      * the maximum number of events.
      * 
      * @returns {boolean}
      */
    isAddDisabled() {
        const eventProps = this.state.insertEvent && this.state.insertEvent.other;
        return (eventProps || []).length >= MAX_EVENT_PROPERTIES;
    }


    /**
     * Remove custom property from Event
     * @param {*} aEventProperty 
     */
    removeCustomEventProperty(aEventProperty) {
        const event = this.state.insertEvent;
        const currentInsertEventProps = event.other;
        const index = currentInsertEventProps.findIndex(aProp => aProp.id === aEventProperty.id);
        const newInsertEventProps = [
            ...currentInsertEventProps.slice(0, index),
            ...currentInsertEventProps.slice(index + 1)
        ]

        const newEvent = new Event(event.id, event.eventTime, event.label, newInsertEventProps);
        this.setState({ insertEvent: newEvent });
    }


    /**
     * Update event with the new event time
     * @param {*} newEventTime 
     * @param {*} event 
     */
    onEventTimeChanged(newEventTime, event) {
        const newEvent = new Event(event.id, newEventTime, event.label, event.other);
        this.setState({ insertEvent: newEvent });
    }


    /**
     * Update event with the new label
     * @param {*} newLabel 
     * @param {*} event 
     */
    onLabelChanged(newLabel, event) {
        const newEvent = new Event(event.id, event.eventTime, newLabel, event.other);
        this.setState({ insertEvent: newEvent });
    }


    /**
     * Update event with the changed custom property
     * @param {*} newProps 
     * @param {*} event 
     */
    onCustomEventPropertyChanged(newProps, event) {
        const newEvent = new Event(event.id, event.eventTime, event.label, newProps);
        this.setState({ insertEvent: newEvent });
    }


    render() {
        const insertEvent = this.state.insertEvent || new Event();

        // Event time 
        const eventTimeProps = {
            id: insertEvent.id + '_eventTime',
            maxlength: '128',
            className: 'eventLabel',
            label: Labels.eventTime,
            text: insertEvent['eventTime'] || '',
            invalidMessage: Labels.eventTimeInvalid,
            isValid: () => insertEvent.isEventTimeValid(),
            onTextChange: (event) => {
                const newTime = event.target.value;
                this.onEventTimeChanged(newTime, insertEvent);
            }
        }

        // Label
        const eventLabelProps = {
            id: insertEvent.id + '_eventLabel',
            maxlength: '128',
            className: 'eventLabel',
            label: Labels.eventLabel,
            text: insertEvent['label'] || '',
            isValid: () => insertEvent.isLabelValid(),
            invalidMessage: Labels.eventLabelInvalid,
            onTextChange: (event) => {
                const newLabel = event.target.value;
                this.onLabelChanged(newLabel, insertEvent)
            }
        }

        const eventCustomProperties = (insertEvent.other || []);

        // Special handle of numberOfAdsInBreak
        let numberOfAdsInBreakProps = null;
        const numberOfAdsInBreak = eventCustomProperties.find(aProp => aProp.label === Event.NUMBER_OF_ADS_IN_BREAK);
        if (numberOfAdsInBreak) {
            numberOfAdsInBreakProps = {
                label: 'Number of Slots in Insert',
                name: Event.NUMBER_OF_ADS_IN_BREAK,
                value: numberOfAdsInBreak.value,
                options: [
                    { label: '1', value: '1' },
                    { label: '2', value: '2' },
                    { label: '3', value: '3' },
                    { label: '4', value: '4' },
                    { label: '5', value: '5' }
                ],
                onChange: (event) => {
                    const newNumberOfAds = event.target.value;
                    const newNumberOfAdsProp = { ...numberOfAdsInBreak, value: newNumberOfAds };
                    const index = eventCustomProperties.findIndex(aOption => aOption.id === newNumberOfAdsProp.id);

                    const newEventProps = [
                        ...eventCustomProperties.slice(0, index),
                        newNumberOfAdsProp,
                        ...eventCustomProperties.slice(index + 1)
                    ]
                    this.onCustomEventPropertyChanged(newEventProps, insertEvent);
                }
            }
        }

        // Rest of properties        
        const eventCustomPropertiesWithoutNumberOfAds = eventCustomProperties.filter(aProp => aProp.label !== Event.NUMBER_OF_ADS_IN_BREAK);

        const eventProperties = eventCustomPropertiesWithoutNumberOfAds.map((aCustomEventProp, index) => {
            return {
                id: aCustomEventProp.id,
                labelProps: {
                    id: aCustomEventProp.id + '_label',
                    maxlength: '128',
                    className: 'event_propery_item',
                    text: aCustomEventProp.label,
                    autoFocus: this.state.focusId === aCustomEventProp.id,
                    invalidMessage: Labels.eventCustomPropertyLabelInvalid,
                    isValid: () => insertEvent.isCustomPropertyLabelValid(aCustomEventProp.label),
                    onTextChange: (event) => {
                        const newPropLabel = event.target.value;
                        const newMetadataProp = { ...aCustomEventProp, label: newPropLabel };
                        const index = eventCustomProperties.findIndex(aOption => aOption.id === aCustomEventProp.id);
                        const newEventProps = [
                            ...eventCustomProperties.slice(0, index),
                            newMetadataProp,
                            ...eventCustomProperties.slice(index + 1)
                        ]
                        this.onCustomEventPropertyChanged(newEventProps, insertEvent);
                    }
                },
                valueProps: {
                    id: aCustomEventProp.id + '_value',
                    maxlength: '128',
                    className: 'event_propery_item',
                    text: aCustomEventProp.value,
                    invalidMessage: Labels.eventCustomPropertyValueInvalid,
                    isValid: () => insertEvent.isCustomPropertyValueValid(aCustomEventProp.value),
                    onTextChange: (event) => {
                        const newPropValue = event.target.value;
                        const newProp = { ...aCustomEventProp, value: newPropValue };
                        const index = eventCustomProperties.findIndex(aOption => aOption.id === aCustomEventProp.id);
                        const newEventProps = [
                            ...eventCustomProperties.slice(0, index),
                            newProp,
                            ...eventCustomProperties.slice(index + 1)
                        ]
                        this.onCustomEventPropertyChanged(newEventProps, insertEvent);
                    }
                }
            }
        });


        const elements = eventProperties.map((aProp) =>
            <li key={aProp.id}>
                <TextInput {...aProp.labelProps} />
                <span>:</span>
                <TextInput {...aProp.valueProps} />
                <button className='delete eventPropertyDelete' title={Labels.deleteButton} onClick={this.removeCustomEventProperty.bind(this, aProp)}>
                    <DeleteIcon />
                    Remove Event Property
                </button>
            </li>
        );

        return (
            <section className='eventEditor card' >
                <header>{Labels.insertEditorTitle} <button className='close' title={Labels.closeButton} onClick={this.onClose.bind(this)}>✕</button></header>

                <div className='eventEditorContent'>
                    <div className='eventTimeLabel'>
                        <TextInput {...eventTimeProps} />
                        <TextInput {...eventLabelProps} />
                    </div>
                    {numberOfAdsInBreakProps ?
                        <div className='eventTimeLabel numberOfAds'>
                            <ButtonGroup {...numberOfAdsInBreakProps} />
                            {/* <div className='explanation'>
                                The number of dynamic Ads to be inserted by the
                                Ad provider, when the podcast becomes old enough.
                            </div> */}
                        </div>
                        : ''
                    }
                    <h4>{Labels.eventEditorOtherTitle}
                        <button className='add eventPropertyAdd' title={this.isAddDisabled() ? Labels.maxEventDetails : Labels.addButton}
                            onClick={this.addCustomEventProperty.bind(this)}
                            disabled={this.isAddDisabled()}>
                            <AddIcon />
                            Add Event Property
                        </button>
                    </h4>
                    <ul>
                        {elements}
                    </ul>
                </div>
            </section>
        );
    }
}

InsertEditor.propTypes = {
    event: PropTypes.instanceOf(Event),
    editorClose: PropTypes.func.isRequired,
    saveEvent: PropTypes.func.isRequired,
}

export default InsertEditor;