import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setEvents, addEvent, deleteEvent } from '../store/events/actions';
import EventComponent from './EventComponent';
import Event from '../store/events/Event';
import Labels from '../assets/labels.json';
import uuid from 'uuid/v4';
import { ReactComponent as AddIcon } from '../vu/svg/primary/add-icon.svg';
import { ReactComponent as ViewIcon } from '../vu/svg/primary/eye-icon.svg';
import Select from 'react-select';
import utils from '../utils/utils';
import { generateEvents } from '../store/events/api';

const MAX_NUMBER_OF_EVENTS = 1500;

/**
 * Renders the Events section of the app.  
 * Displays the section title and the list of events.
 * 
 * Usage:
 * ```js
 * <Events />
 * ```
 * 
 * Models, instances of Event, are sent as events prop:
 * ```js
 * events = [
 *  new Event('id1', '00:01:00.000', 'start', [{ 'id': 'prop1', 'label': 'prop1', 'value': 'value1' }]),
 *  new Event('id2', '00:11:00.000', 'end')
 * ]
 * ```
 */
export class Events extends Component {

    options = [];
    itemsOnPage = 1000;


    constructor(props) {
        super(props);

        const stateFromProps = this.createStateFromProps(props);
        this.state = { ...stateFromProps };
    }


    /**
     * Adds a new empty event at 00:00:00.000
     */
    addEvent() {
        const id = uuid();
        const newEvent = new Event(id, '00:00:00.000', '', []);
        this.props.addEvent(newEvent);
        this.props.selectEventRow(newEvent.id);
    }


    /**
     * Select the row for the event with the provided id
     * @param {string} eventId Event id
     */
    selectRow(eventId) {
        this.props.selectEventRow(eventId);
    }


    /**
     * Duplicates the event with the provided id.
     * @param {string} eventId Event id
     */
    duplicateRow(eventId) {
        if (eventId) {
            const selectedEvent = this.props.events.find((anEvent) => anEvent.id === eventId);
            const newEvent = new Event(uuid(), selectedEvent.eventTime, selectedEvent.label, selectedEvent.other);
            this.props.addEvent(newEvent);
            this.props.selectEventRow(newEvent.id);
        }
    }


    /**
     * Create state from props
     * @param {*} props 
     */
    createStateFromProps(props) {
        const listItems = props.events.map((anEvent) => {
            let valid = utils.isIntervalEventValid(anEvent, props.events);
            return <EventComponent key={anEvent.id} {...anEvent} {...props}
                duplicateRow={this.duplicateRow.bind(this)}
                selectEventRow={this.selectRow.bind(this)}
                valid={valid}
                intervalKey={anEvent.intervalKey}
                isAddDisabled={props.events.length >= MAX_NUMBER_OF_EVENTS}
                selected={props.selectedEventId === anEvent.id ? true : false} />
        });

        const generatedEvents = props.events.filter(anEvent => anEvent.generated);
        const invalidEvents = props.events.filter(anEvent => !anEvent.valid);

        let startIndex = 1;
        let endIndex = this.itemsOnPage;

        // find page with selected item index
        const selectedEventId = props.selectedEventId;
        if (selectedEventId) {
            const selectedEventIndex = props.events.findIndex(item => {
                return item.id === selectedEventId
            })

            const page = Math.floor(selectedEventIndex / this.itemsOnPage);
            startIndex = page * this.itemsOnPage + 1;
            endIndex = Math.min(startIndex + this.itemsOnPage - 1, listItems.length);
        }
        const havePagination = listItems.length > this.itemsOnPage;
        return { listItems, generatedEvents, invalidEvents, startIndex, endIndex, havePagination };
    }


    /**
     * Update state based on new props
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        const stateFromProps = this.createStateFromProps(nextProps);
        this.setState({ ...stateFromProps });
    }


    /**
     * Get the predefined events when the component is created.
     * The events are used to fill a drop down.
     */
    componentWillMount() {
        const configurationEvents = this.props.configuration ? this.props.configuration.events : null;
        if (configurationEvents) {
            this.options = [
                { label: 'Custom', value: 'customEvent' }
            ].concat(configurationEvents.map(aEvent => {
                const o = {
                    ...aEvent,
                    value: aEvent.key || aEvent.label,
                }
                return o;
            }));
        }
    }


    /**
     * Scroll to selected row
     */
    componentDidUpdate() {
        if (this.props.selectedEventId) {
            const el = document.querySelector('.events li.selected');
            if (el && el.scrollIntoView) {
                el.scrollIntoView();
            }
        }
    }

    ////////// Pagination ////////

    /**
     * When pagination is used, only some of the events are visible.
     * If no pagination is used, all the events are visible.
     */
    getVisibleEvents() {
        const start = this.state.startIndex;
        const end = this.state.endIndex;

        const visibleEvents = this.state.listItems.slice(start - 1, end);
        return visibleEvents;
    }


    /**
     * Show the events from the previous "page" when pagination is used
     */
    previousEvents() {
        const start = Math.max(this.state.startIndex - this.itemsOnPage, 1);
        const end = Math.min(start + this.itemsOnPage - 1, this.state.listItems.length);
        this.setState({ startIndex: start, endIndex: end })
    }



    /**
     * Returns true when we are on the 1st page of events
     * @returns {boolean}
     */
    isPreviousEventsDisabled() {
        return this.state.startIndex === 1;
    }


    /**
     * Show the events from the next "page" when pagination is used
     */
    nextEvents() {
        const start = Math.min(this.state.endIndex + 1, this.state.listItems.length);
        const end = Math.min(start + this.itemsOnPage - 1, this.state.listItems.length);

        this.setState({ startIndex: start, endIndex: end })
    }


    /**
     * Returns true when we are on the last page of events
     * @returns {boolean}
     */
    isNextEventsDisabled() {
        return this.state.endIndex === this.state.listItems.length;
    }


    // Generate

    /**
     * Generate events
     */
    generateEvents() {
        const sid = this.props.sessionId;
        this.props.generateEvents(sid, '5');
    }


    /**
     * Remove generated events
     */
    removeGeneratedEvents() {
        const nonGeneratedEvents = this.props.events.filter(anEvent => !anEvent.generated);
        this.props.setEvents(nonGeneratedEvents);
    }


    /**
     * Handles predefined(configuration) events
     */
    handleAddEvent(selectedOption) {
        if (selectedOption.value === 'interval') {
            return this.handleAddInterval(selectedOption);
        } else if (selectedOption.value === 'insert') {
            return this.handleAddInsert(selectedOption);
        }

        const id = uuid();

        const label = selectedOption.label;
        let eventTime = selectedOption.eventTime || '00:00:00.000';

        // podcastEnd event's time should be the end of the file
        if (label === 'podcastEnd' && this.props.fileDuration) {
            eventTime = this.props.fileDuration;
        }
        // podcast98 event's time should be 98% from the file duration
        else if (label === 'podcast98' && this.props.fileDuration) {
            eventTime = utils.convertSecondsToEventTime(utils.convertEventTimeToSeconds(this.props.fileDuration) * 0.98);
        }

        const otherProps = { ...selectedOption };
        delete otherProps.label;
        delete otherProps.eventTime;
        delete otherProps.value;

        const mappedOtherProps = Object.keys(otherProps).map(aProp => {
            return { id: uuid(), label: aProp, value: '' }
        })

        const newEvent = new Event(id, eventTime, label, mappedOtherProps);
        this.props.addEvent(newEvent);
        this.props.selectEventRow(newEvent.id);
    }


    handleAddInterval(intervalData) {
        const intervals = (this.props.events || []).filter(anEvent => anEvent.intervalKey);
        const noOfIntervals = Math.floor(intervals.length / 2);
        let labelSuffix = noOfIntervals + 1;

        const { label, eventTime, durationInSeconds } = intervalData;
        const numberOfAds = Math.floor(durationInSeconds / 15);
        const mappedOtherProps = (intervalData.other || []).map(aProp => {
            return { id: uuid(), label: aProp, value: aProp === Event.NUMBER_OF_ADS_IN_BREAK ? '' + numberOfAds : '' }
        })

        const { startEvent, endEvent } = Event.createIntervalEvents('event' + labelSuffix, eventTime, durationInSeconds, mappedOtherProps);

        this.props.addEvent(startEvent);
        this.props.addEvent(endEvent);
        this.props.selectEventRow(startEvent.id);
    }


    handleAddInsert(insertData) {
        const inserts = (this.props.events || []).filter(anEvent => anEvent.isInsert());
        let labelSuffix = inserts.length + 1;

        const { label, eventTime } = insertData;
        const mappedOtherProps = (insertData.other || []).map(aProp => {
            return { id: uuid(), label: aProp, value: aProp === Event.NUMBER_OF_ADS_IN_BREAK ? '1' : '' }
        })

        const insertEvent = new Event(uuid(), eventTime, 'eventInsert' + labelSuffix, mappedOtherProps);
        this.props.addEvent(insertEvent);
        this.props.selectEventRow(insertEvent.id);
    }



    viewXML() {
        this.props.showXML();
    }

    /**
     * Returns true when events cannot be added,
     * for example because the number of events exceeds
     * the maximum number of events.
     * 
     * @returns {boolean}
     */
    isAddEventDisabled() {
        return this.state.listItems.length >= MAX_NUMBER_OF_EVENTS;
    }


    /**
     * Returns true when events cannot be generated,
     * for example because the number of events exceeds or will exceed
     * the maximum number of events.
     * 
     * @returns {boolean}
     */
    isGenerateButtonDisabled() {
        let numberOfEvents = this.state.listItems.length;
        const haveGeneratedEvents = this.state.generatedEvents.length > 0;
        if (!haveGeneratedEvents) {
            const seconds = utils.convertEventTimeToSeconds(this.props.fileDuration);
            numberOfEvents += seconds / 5;
        }
        return numberOfEvents >= MAX_NUMBER_OF_EVENTS;
    }


    isViewXMLButtonDisabled() {
        return false;
        // const intervals = (this.props.events || []).filter(anEvent => anEvent.intervalKey);
        // return intervals.length === 0;
    }


    render() {
        const listItems = this.state.listItems;
        const generatedEvents = this.state.generatedEvents;
        const invalidEvents = this.state.invalidEvents;

        let classes = invalidEvents.length > 0 ? 'events card invalid' : 'events card';
        if (this.state.havePagination) {
            classes += ' pagination';
        }
        return (
            <section className={classes}>
                <header>{Labels.eventsTitle} ({listItems.length})
                    <span>
                        {this.options.length > 0 ?
                            <button className='primary viewAdMetadata' title="View Ad provider metadata"
                                disabled={this.isViewXMLButtonDisabled()}
                                onClick={this.viewXML.bind(this)}>
                                <ViewIcon />
                                XML
                        </button>
                            : ''
                        }
                        {/* If we have predefined events show a dropdown with them */}
                        {this.options.length > 0
                            ?
                            <Select
                                placeholder='Add event'
                                className='dropdown'
                                classNamePrefix='dropdown'
                                value={null}
                                isDisabled={this.isAddEventDisabled()}
                                onChange={this.handleAddEvent.bind(this)}
                                options={this.options}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#fff'
                                    },
                                })}
                            />
                            :
                            <button className='add eventAdd'
                                title={Labels.addButton}
                                disabled={this.isAddEventDisabled()}
                                onClick={this.addEvent.bind(this, this.props.addEvent)}>
                                <AddIcon />
                                Add Event
                            </button>
                        }

                        <button className='secondary generate' title={Labels.generate5sButton}
                            disabled={this.isGenerateButtonDisabled()}
                            onClick={generatedEvents.length > 0 ? this.removeGeneratedEvents.bind(this) : this.generateEvents.bind(this)}>
                            {generatedEvents.length > 0 ? Labels.removeGeneratedEventsButton : Labels.generate5sButton}
                        </button>
                    </span>
                </header>
                <ul className='eventsContainer'>
                    {this.getVisibleEvents()}
                </ul>
                <footer>
                    <button className='secondary'
                        title='Previous'
                        onClick={this.previousEvents.bind(this)}
                        disabled={this.isPreviousEventsDisabled()}>&laquo;</button>
                    <span className='range'>{this.state.startIndex} - {this.state.endIndex}</span>
                    <button className='secondary'
                        title='Next'
                        onClick={this.nextEvents.bind(this)}
                        disabled={this.isNextEventsDisabled()}>&raquo;</button>
                </footer>
            </section>
        );
    }
}

Events.propTypes = {
    sessionId: PropTypes.string.isRequired,
    events: PropTypes.array.isRequired,
    fileDuration: PropTypes.string,
    configuration: PropTypes.object,
    setEvents: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
    selectEventRow: PropTypes.func.isRequired,
    generateEvents: PropTypes.func.isRequired,
    showXML: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    sessionId: state.session.sessionId,
    events: state.events,
    fileDuration: state.session.fileDuration,
    configuration: state.session.configuration
});
const mapDispatchToProps = { setEvents, addEvent, deleteEvent, generateEvents };

export default connect(mapStateToProps, mapDispatchToProps)(Events);