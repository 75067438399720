import uuid from 'uuid/v4';

const urlRegexp = /^(https:\/\/www\.|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;


/**
 * Tracking URL data model
 * Contains functions that transforms tracking urls from raw JSON to UI models.
 * 
 * Raw JSON for tracking urls
 * ```js
 *  [
 *    'https://tracking.npr.org'
 *    'https://advendor.com'
 *  ]
 * ```
 * 
 *  UI models:
 * ```js
 * [
 *   {
 *     id: "1885b3e0-994a-49bd-afe1-bce8741ccd9f",
 *     url: "https://tracking.npr.org"
 *   },
 *   {
 *     id: "e3e3acce-73ad-4138-914b-0fa7af92d5f3",
 *     url: "https://advendor.com"
 *   }
 * ]
 * ```
 */
export default class TrackingUrl {

    constructor(id, url) {
        this.id = id;
        this.url = url;

        Object.freeze(this);
    }


    get valid() {
        return urlRegexp.test(this.url)
    }


    static parse = (data = "") => {
        return new TrackingUrl(uuid(), data);
    }


    static serialize = (aTrackingUrl) => {
        return aTrackingUrl ? aTrackingUrl.url : '';
    }
}