import {
    SESSION_ID,
    FILE_NAME,
    SELECTED_EVENT_ID,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE,
    DOWNLOAD_SUCCESS,
    DOWNLOAD_FAILURE,
    FETCH_METADATA_REQUEST,
    FETCH_METADATA_FAILURE,
    FETCH_METADATA_SUCCESS,
    SAVE_METADATA_REQUEST,
    SAVE_METADATA_FAILURE,
    SAVE_METADATA_SUCCESS,
    CLEAR_ERRORS,
    CLEAR_INFO,
    INCREMENT_UPLOADS,
    RESET_UPLOADS,
    SET_INFO,
    FILE_DURATION,
    FETCH_CONFIGURATION_REQUEST,
    FETCH_CONFIGURATION_FAILURE,
    FETCH_CONFIGURATION_SUCCESS,
    FETCH_EVENTS_FROM_XML_REQUEST,
    FETCH_EVENTS_FROM_XML_FAILURE
} from './types';
import { GENERATE_EVENTS_FAILURE } from '../events/types';
import deepFreeze from '../deepFreeze';
import utils from '../../utils/utils';


const defaultState = utils.loadSessionState() || {
    sessionId: '',
    fileName: '',
    fileDuration: '',
    uploads: 0,
    time: '',
    fetchingMetadata: false,
    fetchingConfiguration: false,
    configuration: null,
    sessionError: {
        type: '',
        error: ''
    },
    sessionInfo: '',
    savingMetadata: false,
};


export default (state = defaultState, action) => {
    let newState;
    switch (action.type) {
        case SESSION_ID:
            newState = { ...state, sessionId: action.sessionId }
            utils.saveSessionState(newState);

            deepFreeze(newState);
            return newState;
        case FILE_NAME:
            newState = { ...state, fileName: action.fileName }
            utils.saveSessionState(newState);

            deepFreeze(newState);
            return newState;
        case FILE_DURATION:
            newState = { ...state, fileDuration: action.fileDuration }
            deepFreeze(newState);
            return newState;
        case SELECTED_EVENT_ID:
            newState = { ...state, selectedRowId: action.selectedRowId }
            deepFreeze(newState);
            return newState;
        case CLEAR_ERRORS:
        case UPLOAD_SUCCESS:
        case DOWNLOAD_SUCCESS:
            newState = { ...state, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case CLEAR_INFO:
            newState = { ...state, sessionInfo: '' }
            deepFreeze(newState);
            return newState;
        case SET_INFO:
            newState = { ...state, sessionInfo: action.message }
            deepFreeze(newState);
            return newState;
        case UPLOAD_FAILURE:
            newState = { ...state, sessionError: { type: 'upload', error: action.error } }
            deepFreeze(newState);
            return newState;
        case DOWNLOAD_FAILURE:
            newState = { ...state, sessionError: { type: 'download', error: action.error } }
            deepFreeze(newState);
            return newState;
        case FETCH_METADATA_REQUEST:
            newState = { ...state, fetchingMetadata: true, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case FETCH_METADATA_FAILURE:
            newState = { ...state, fetchingMetadata: false, sessionError: { type: 'fetchMetadata', error: action.error } }
            deepFreeze(newState);
            return newState;
        case FETCH_METADATA_SUCCESS:
            newState = { ...state, fetchingMetadata: false, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case SAVE_METADATA_REQUEST:
            newState = { ...state, savingMetadata: true, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case SAVE_METADATA_FAILURE:
            newState = { ...state, savingMetadata: false, sessionError: { type: 'saveMetadata', error: action.error } }
            deepFreeze(newState);
            return newState;
        case SAVE_METADATA_SUCCESS:
            newState = { ...state, savingMetadata: false, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case FETCH_CONFIGURATION_REQUEST:
            newState = { ...state, fetchingConfiguration: true, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case FETCH_CONFIGURATION_FAILURE:
            newState = { ...state, fetchingConfiguration: false, sessionError: { type: 'fetchConfiguration', error: action.error } }
            deepFreeze(newState);
            return newState;
        case FETCH_CONFIGURATION_SUCCESS:
            newState = { ...state, fetchingConfiguration: false, sessionError: {}, configuration: action.configuration }
            utils.saveSessionState(newState);
            deepFreeze(newState);
            return newState;
        case FETCH_EVENTS_FROM_XML_REQUEST:
            newState = { ...state, fetchingEventsFromXML: true, sessionError: {} }
            deepFreeze(newState);
            return newState;
        case FETCH_EVENTS_FROM_XML_FAILURE:
            newState = { ...state, fetchingEventsFromXML: false, sessionError: { type: 'fetchEventsFromXML', error: action.error } }
            deepFreeze(newState);
            return newState;
        case GENERATE_EVENTS_FAILURE:
            newState = { ...state, sessionError: { type: 'generateEvents', error: action.error } }
            deepFreeze(newState);
            return newState;
        case INCREMENT_UPLOADS:
            newState = { ...state };
            if (!newState.time) {
                newState.time = '' + Date.now();
            } else {
                newState.time = newState.time + ',' + Date.now();
            }
            newState.uploads++;
            utils.saveSessionState(newState);
            deepFreeze(newState);
            return newState;
        case RESET_UPLOADS:
            newState = { ...state, uploads: 0, time: '' };
            utils.saveSessionState(newState);
            deepFreeze(newState);
            return newState;
        default:
            return state;
    }
};