import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import {
    addCustomMetadata,
    editCustomMetadata,
    deleteCustomMetadata
} from '../store/customMetadata/actions';
import Labels from '../assets/labels.json';
import CustomMetadata from '../store/customMetadata/CustomMetadata';
import TextInput from './TextInput';
import { ReactComponent as AddIcon } from '../vu/svg/primary/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../vu/svg/primary/close-icon.svg';

const MAX_CUSTOM_METADATA = 50;

/**
 * Renders the Custom Metadata section of the app.  
 * Displays the section title and the list of custom metadata.
 * 
 * Usage:
 * ```js
 * <CustomMetadataWrapper />
 * ```
 * Models, instances of CustomMetadata, are sent as customMetadata prop:
 * ```js
 * customMetadata = [
 *  new CustomMetadata('id1', 'label1', 'value1'),
 *  new CustomMetadata('id2', 'label2', 'value2'),
 * ]
 * ```
 */
export class CustomMetadataWrapper extends Component {

    /**
     * Creates a new component
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = { customMetadata: props.customMetadata, focusId: null }
    }


    static propTypes = {
        customMetadata: PropTypes.array.isRequired,
        addCustomMetadata: PropTypes.func.isRequired,
        editCustomMetadata: PropTypes.func.isRequired,
        deleteCustomMetadata: PropTypes.func.isRequired
    }


    /**
     * Updates state on props change
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        this.setState({ customMetadata: nextProps.customMetadata });
    }


    /**
     * Adds a new empty CustomMetadata
     */
    addCustomMetadata() {
        const newMetadata = new CustomMetadata(uuid(), '', '');
        this.props.addCustomMetadata(newMetadata);
        this.setState({ focusId: newMetadata.id });
    }

    /**
     * Returns true when adding custom metadata is not allowed,
     * for example when the maximum number is reached.
     * 
     * @returns {boolean}
     */
    isAddDisabled() {
        return (this.state.customMetadata || []).length >= MAX_CUSTOM_METADATA;
    }


    /**
     * Remove the provided metadata
     * @param {CustomMetadata} aCustomMetadata 
     */
    removeCustomMetadata(aCustomMetadata) {
        this.props.deleteCustomMetadata(aCustomMetadata);
    }


    /**
     * Update the label of a custom metadata
     * @param {string} newLabel The new custom metadata label
     * @param {number} index The index of the custom metadata
     */
    onLabelChange(newLabel, index) {
        const currentMetadata = this.state.customMetadata;
        const aCustomMetadata = currentMetadata[index];
        const updatedMetadata = new CustomMetadata(aCustomMetadata.id, newLabel, aCustomMetadata.value);

        const newMetadata = [
            ...currentMetadata.slice(0, index),
            updatedMetadata,
            ...currentMetadata.slice(index + 1)
        ]
        this.setState({ customMetadata: newMetadata });
        this.props.editCustomMetadata(updatedMetadata);
    }


    /**
     * Update the value of a custom metadata
     * @param {string} newValue 
     * @param {number} index 
     */
    onValueChange(newValue, index) {
        const currentMetadata = this.state.customMetadata;
        const aCustomMetadata = currentMetadata[index];
        const updatedMetadata = new CustomMetadata(aCustomMetadata.id, aCustomMetadata.label, newValue);

        const newMetadata = [
            ...currentMetadata.slice(0, index),
            updatedMetadata,
            ...currentMetadata.slice(index + 1)
        ]
        this.setState({ customMetadata: newMetadata });
        this.props.editCustomMetadata(updatedMetadata);
    }


    /**
     * Returns true if there is more then one custom metadata with the same label and value
     * @param {CustomMetadata} customMetadata 
     * @return {boolean}
     */
    isDuplicate(customMetadata) {
        if (!customMetadata) {
            return false;
        }

        const dups = this.state.customMetadata.filter(aCustomMetadata => aCustomMetadata.label === customMetadata.label && aCustomMetadata.id !== customMetadata.id);
        return dups.length > 0;
    }


    render() {
        const customMetadata = this.state.customMetadata || [];
        const textInputProps = customMetadata.map((aCustomMetadata, index) => {
            // create props for text inputs used to render the custom metadata label and value
            return {
                id: aCustomMetadata.id,
                labelProps: {
                    id: aCustomMetadata.id + '_label',
                    maxlength: '128',
                    className: 'event_propery_item',
                    text: aCustomMetadata.label,
                    autoFocus: this.state.focusId === aCustomMetadata.id,
                    invalidMessage: Labels.customMetataLabelInvalid,
                    isValid: () => aCustomMetadata.isLabelValid() && !this.isDuplicate(aCustomMetadata),
                    onTextChange: (event) => {
                        const newLabel = event.target.value;
                        this.onLabelChange(newLabel, index);
                    }
                },
                valueProps: {
                    id: aCustomMetadata.id + '_value',
                    maxlength: '128',
                    className: 'event_propery_item',
                    text: aCustomMetadata.value,
                    invalidMessage: Labels.customMetadataValueInvalid,
                    isValid: () => aCustomMetadata.isValueValid(),
                    onTextChange: (event) => {
                        const newValue = event.target.value;
                        this.onValueChange(newValue, index)
                    }
                }
            }
        });

        // create an element for each custom metadata
        const customMetadataElements = textInputProps.map((aCustomMetadataProps) =>
            <li key={aCustomMetadataProps.id}>
                <TextInput {...aCustomMetadataProps.labelProps} />
                <span>:</span>
                <TextInput {...aCustomMetadataProps.valueProps} />
                <button className='delete customMetadataDelete'
                    title={Labels.deleteButton}
                    onClick={this.removeCustomMetadata.bind(this, customMetadata.find(aCM => aCM.id === aCustomMetadataProps.id))}>
                    <DeleteIcon />
                    Delete Custom Metadata
                </button>
            </li>
        );

        // find if all metadata is valid
        const allCustomMetadataValid = customMetadata.reduce((state, aCustomMetadata) => {
            return state && aCustomMetadata.valid
        }, true);

        return (
            <section className={allCustomMetadataValid ? 'customMetadata card' : 'customMetadata card invalid'}>
                <header>{Labels.customMetadataTitle} ({customMetadataElements.length})
                    <button className='add customMetadataAdd'
                        title={this.isAddDisabled() ? Labels.maxCustomMetadata : Labels.addButton}
                        onClick={this.addCustomMetadata.bind(this)}
                        disabled={this.isAddDisabled()}>
                        <AddIcon />
                        Add Custom Metadata
                    </button>
                </header>
                <ul>
                    {customMetadataElements}
                </ul>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    customMetadata: state.customMetadata
});
const mapDispatchToProps = { addCustomMetadata, editCustomMetadata, deleteCustomMetadata };
export default connect(mapStateToProps, mapDispatchToProps)(CustomMetadataWrapper);
