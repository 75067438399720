import {
    SET_CUSTOM_METADATA,
    ADD_CUSTOM_METADATA,
    EDIT_CUSTOM_METADATA,
    DELETE_CUSTOM_METADATA
} from './types';


export const setCustomMetadata = allCustomMetadata => ({
    type: SET_CUSTOM_METADATA,
    customMetadata: allCustomMetadata
});


export const addCustomMetadata = customMetadata => ({
    type: ADD_CUSTOM_METADATA,
    customMetadata,
});


export const editCustomMetadata = customMetadata => ({
    type: EDIT_CUSTOM_METADATA,
    customMetadata,
});


export const deleteCustomMetadata = customMetadata => ({
    type: DELETE_CUSTOM_METADATA,
    customMetadata,
});