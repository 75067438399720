import {
    SET_CUSTOM_METADATA,
    ADD_CUSTOM_METADATA,
    EDIT_CUSTOM_METADATA,
    DELETE_CUSTOM_METADATA
} from './types';
import deepFreeze from '../deepFreeze';


export default (state = [], action) => {
    let newState;
    switch (action.type) {
        case SET_CUSTOM_METADATA:
            newState = [...action.customMetadata];
            deepFreeze(newState);
            return newState;
        case ADD_CUSTOM_METADATA:
            return [...state, action.customMetadata];
        case EDIT_CUSTOM_METADATA:
            const index = state.findIndex(aCustomMetadata => aCustomMetadata.id === action.customMetadata.id);
            newState = [
                ...state.slice(0, index),
                action.customMetadata,
                ...state.slice(index + 1)
            ];
            deepFreeze(newState);
            return newState;
        case DELETE_CUSTOM_METADATA:
            newState = state.filter(aCustomMetadata => aCustomMetadata.id !== action.customMetadata.id);
            deepFreeze(newState);
            return newState;
        default:
            return state;
    }
};