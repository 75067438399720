
import {
    SESSION_ID,
    FILE_NAME,
    FILE_DURATION,
    SELECTED_EVENT_ID,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE,
    DOWNLOAD_SUCCESS,
    DOWNLOAD_FAILURE,
    FETCH_METADATA_REQUEST,
    FETCH_METADATA_FAILURE,
    FETCH_METADATA_SUCCESS,
    SAVE_METADATA_REQUEST,
    SAVE_METADATA_FAILURE,
    SAVE_METADATA_SUCCESS,
    CLEAR_ERRORS,
    CLEAR_INFO,
    INCREMENT_UPLOADS,
    RESET_UPLOADS,
    SET_INFO,
    FETCH_CONFIGURATION_REQUEST,
    FETCH_CONFIGURATION_FAILURE,
    FETCH_CONFIGURATION_SUCCESS,
    FETCH_EVENTS_FROM_XML_REQUEST,
    FETCH_EVENTS_FROM_XML_FAILURE
} from './types';


export const setSessionId = sessionId => ({
    type: SESSION_ID,
    sessionId,
});


export const setFileName = fileName => ({
    type: FILE_NAME,
    fileName,
});


export const setFileDuration = fileDuration => ({
    type: FILE_DURATION,
    fileDuration,
});


export const selectRowId = id => ({
    type: SELECTED_EVENT_ID,
    selectedRowId: id,
});


export const clearErrors = () => ({
    type: CLEAR_ERRORS
})

export const clearInfo = () => ({
    type: CLEAR_INFO
})

export const incrementUploads = () => ({
    type: INCREMENT_UPLOADS
})

export const resetUploads = () => ({
    type: RESET_UPLOADS
})

export const setInfo = (message) => ({
    type: SET_INFO,
    message
})


/////// Upload file ////////

export const uploadFailure = error => ({
    type: UPLOAD_FAILURE,
    error,
});

export const uploadSuccess = fileName => ({
    type: UPLOAD_SUCCESS,
    fileName,
});


/////// Download file ////////

export const downloadFailure = error => ({
    type: DOWNLOAD_FAILURE,
    error,
});

export const downloadSuccess = fileName => ({
    type: DOWNLOAD_SUCCESS,
    fileName,
});



/////// Fetch Metadata ////////

export const requestMetadata = sid => ({
    type: FETCH_METADATA_REQUEST,
    sessionId: sid
})

export const requestMetadataFailure = error => ({
    type: FETCH_METADATA_FAILURE,
    error
})

export const requestMetadataSuccess = metadata => ({
    type: FETCH_METADATA_SUCCESS,
    metadata
})


/////// Save Metadata ////////

export const saveMetadataRequest = sid => ({
    type: SAVE_METADATA_REQUEST,
    sessionId: sid
})

export const saveMetadataFailure = error => ({
    type: SAVE_METADATA_FAILURE,
    error
})

export const saveMetadataSuccess = metadata => ({
    type: SAVE_METADATA_SUCCESS,
    metadata
})


/////// Fetch Configuration ////////

export const requestConfiguration = sid => ({
    type: FETCH_CONFIGURATION_REQUEST,
    sessionId: sid
})

export const requestConfigurationFailure = error => ({
    type: FETCH_CONFIGURATION_FAILURE,
    error
})

export const requestConfigurationSuccess = configuration => ({
    type: FETCH_CONFIGURATION_SUCCESS,
    configuration
})


////// Get events generated from XML ///// 

export const generateEventsFromXML = sid => ({
    type: FETCH_EVENTS_FROM_XML_REQUEST,
    sessionId: sid
})

export const generateEventsFromXMLFailure = error => ({
    type: FETCH_EVENTS_FROM_XML_FAILURE,
    error
})