import { ADD_EVENT, EDIT_EVENT, DELETE_EVENT, SET_EVENTS, GENERATE_EVENTS_SUCCESS, ADD_EVENTS } from './types';
import deepFreeze from '../deepFreeze';
import utils from '../../utils/utils';

const defaultState = [];


const compareEvents = (eventA, eventB) => {
    const eventATime = utils.convertEventTimeToSeconds(eventA.eventTime);
    const eventBTime = utils.convertEventTimeToSeconds(eventB.eventTime);

    if (eventATime === eventBTime) {
        return eventA.label < eventB.label ? -1 : (eventA.label > eventB.label ? 1 : 0)
    } else {
        return eventATime - eventBTime;
    }
}


export default (state = defaultState, action) => {
    let newState;
    switch (action.type) {
        case SET_EVENTS:
            newState = [...action.events].sort(compareEvents);
            deepFreeze(newState);
            return newState;
        case ADD_EVENT:
            newState = [...state, action.event].sort(compareEvents);
            deepFreeze(newState);
            return newState;
        case EDIT_EVENT:
            const allWithoutTheEditedEvent = state.filter(anEvent => anEvent.id !== action.event.id);
            newState = [
                ...allWithoutTheEditedEvent,
                action.event
            ].sort(compareEvents);
            deepFreeze(newState);
            return newState;
        case DELETE_EVENT:
            if (action.event.intervalKey) {
                const { startEvent = {}, endEvent = {} } = utils.getIntervalEvents(state, action.event.intervalKey);
                if (startEvent.id && endEvent.id) {
                    newState = state.filter(anEvent => !(anEvent.id === startEvent.id || anEvent.id === endEvent.id));
                } else {
                    newState = state.filter(anEvent => anEvent.id !== action.event.id)
                }
            } else {
                newState = state.filter(anEvent => anEvent.id !== action.event.id)
            }
            deepFreeze(newState);
            return newState;
        case ADD_EVENTS:
        case GENERATE_EVENTS_SUCCESS:
            newState = [...state, ...action.events].sort(compareEvents);
            deepFreeze(newState);
            return newState;
        default:
            return state;
    }
};