import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AddIcon } from '../vu/svg/primary/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../vu/svg/primary/close-icon.svg';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import TextInput from './TextInput';
import {
    addTrackingUrl,
    editTrackingUrl,
    deleteTrackingUrl
} from '../store/trackingUrls/actions';
import Labels from '../assets/labels.json';
import TrackingUrl from '../store/trackingUrls/TrackingUrl';

const MAX_TRACKING_URLS = 20;

/**
 * Renders the TrackingUrls section.  
 * 
 * Usage:
 * ```js
 * <TrackingUrls />
 * ```
 * Models, instances of TrackingUrl, are sent as trackingUrls prop:
 * ```js
 * trackingUrls = [
 *  new TrackingUrl('id1', 'url1'),
 *  new TrackingUrl('id2', 'url2')
 * ]
 * ```
 */
export class TrackingUrls extends Component {

    constructor(props) {
        super(props);
        this.state = { trackingUrls: props.trackingUrls, focusId: null }
    }


    /**
     * Add a new empty tracking url
     */
    addUrl() {
        const newUrl = new TrackingUrl(uuid(), 'https://');
        this.props.addTrackingUrl(newUrl);
        this.setState({ focusId: newUrl.id });
    }


    /**
     * Returns true when adding tracking url is not allowed,
     * for example when the maximum number is reached.
     * 
     * @returns {boolean}
     */
    isAddDisabled() {
        return (this.state.trackingUrls || []).length >= MAX_TRACKING_URLS;
    }


    /**
     * Update state on props update
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        this.setState({ trackingUrls: nextProps.trackingUrls });
    }


    /**
     * Remove the tracking url with the provided id
     * @param {string} id 
     */
    removeUrl(id) {
        const currentUrls = this.state.trackingUrls;
        const urlToDelete = currentUrls.find(aUrl => aUrl.id === id);
        this.props.deleteTrackingUrl(urlToDelete);
    }


    /**
     * Update tracking url with the new value
     * @param {string} newUrlValue 
     * @param {TrackingUrl} trackingUrl 
     * @param {number} index 
     */
    onTextChange(newUrlValue, trackingUrl, index) {
        const newUrl = new TrackingUrl(trackingUrl.id, newUrlValue);
        const currentUrls = this.state.trackingUrls;
        const newUrls = [
            ...currentUrls.slice(0, index),
            newUrl,
            ...currentUrls.slice(index + 1)
        ]
        this.setState({ trackingUrls: newUrls });
        this.props.editTrackingUrl(newUrl);
    }


    /**
     * Returns true if there is more then one tracking url with the same value
     * @param {TrackingUrl} trackingUrl 
     * @return {boolean}
     */
    isDuplicate(trackingUrl) {
        if (!trackingUrl) {
            return false;
        }

        const dups = this.state.trackingUrls.filter(aTrackingUrl => aTrackingUrl.url === trackingUrl.url && aTrackingUrl.id !== trackingUrl.id);
        return dups.length > 0;
    }


    render() {
        const trackingUrls = this.state.trackingUrls;
        const textInputProps = trackingUrls.map((trackingUrl, index) => {
            return {
                maxlength: '128',
                className: 'trackingUrlItem',
                id: trackingUrl.id,
                text: trackingUrl.url,
                autoFocus: this.state.focusId === trackingUrl.id,
                invalidMessage: Labels.trackingUrlInvalid,
                isValid: () => trackingUrl.valid && !this.isDuplicate(trackingUrl),
                onTextChange: (event) => {
                    const newUrlValue = event.target.value;
                    this.onTextChange(newUrlValue, trackingUrl, index)
                }
            }
        });

        const trackingUrlsElements = textInputProps.map((aTrackingUrlProps) =>
            <li key={aTrackingUrlProps.id}>
                <TextInput {...aTrackingUrlProps} />
                <button className='delete trackingURLDelete' title={Labels.deleteButton} onClick={this.removeUrl.bind(this, aTrackingUrlProps.id)}>
                    <DeleteIcon />
                    Delete Tracking Url
                </button>
            </li>
        );

        const allTrackingUrlsValid = trackingUrls.reduce((state, aTrackingUrl) => {
            return state && aTrackingUrl.valid
        }, true);

        return (
            <section className={allTrackingUrlsValid ? 'trackingUrls card' : 'trackingUrls card invalid'}>
                <header>{Labels.trackingUrlsTitle} ({trackingUrlsElements.length})
                    <button className='add trackingURLAdd' title={this.isAddDisabled() ? Labels.maxTrackingUrls : Labels.addButton}
                        onClick={this.addUrl.bind(this)}
                        disabled={this.isAddDisabled()}>
                        <AddIcon />
                        Add Tracking Url
                    </button>
                </header>
                <ul>
                    {trackingUrlsElements}
                </ul>
            </section>
        );
    }
}

TrackingUrls.propTypes = {
    trackingUrls: PropTypes.array.isRequired,
    addTrackingUrl: PropTypes.func.isRequired,
    editTrackingUrl: PropTypes.func.isRequired,
    deleteTrackingUrl: PropTypes.func.isRequired
}


const mapStateToProps = (state) => ({
    trackingUrls: state.trackingUrls
});
const mapDispatchToProps = { addTrackingUrl, editTrackingUrl, deleteTrackingUrl };

export default connect(mapStateToProps, mapDispatchToProps)(TrackingUrls);