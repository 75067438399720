import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearInfo } from '../store/session/actions';
import Labels from '../assets/labels.json';

/**
 * Renders the info message if any.
 * Information message is stored in session.sessionInfo
 */
export class InfoDisplay extends React.Component {

    onClose() {
        this.props.clearInfo();
    }

    render() {
        let sessionInfo = this.props.sessionInfo;

        return (
            sessionInfo ?
                <section className='info'>
                    <span className='message'>{sessionInfo}
                        &nbsp;
                        <a href="https://n.pr/radeditor" target="_blank" rel="noopener noreferrer">{Labels.sendFeedback}</a>
                    </span>
                    <button className='close' title='Close' onClick={this.onClose.bind(this)}>✕</button>
                </section>
                : null
        )
    }
}

InfoDisplay.propTypes = {
    sessionInfo: PropTypes.string,
    clearInfo: PropTypes.func.isRequired
}

const mapStateToProps = state => state.session;
const mapDispatchToProps = {
    clearInfo
};
export default connect(mapStateToProps, mapDispatchToProps)(InfoDisplay);