import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Events from './Events';
import EventEditor from './EventEditor';
import InsertEditor from './InsertEditor';
import IntervalEditor from './IntervalEditor';
import XMLViewer from './XMLViewer';
import { connect } from 'react-redux';
import { editEvent } from '../store/events/actions';
import { selectRowId } from '../store/session/actions';
import utils from '../utils/utils';


/**
 * Renders the EventsWrapper section of the app.  
 * 
 * Usage:
 * ```js
 * <EventsWrapper />
 * ```
 */
export class EventsWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = { selectedEventId: null, showXML: false }
    }

    /**
     * Update state on props update
     * @param {*} nextProps 
     */
    componentWillReceiveProps(nextProps) {
        const haveSelectedRow = nextProps.events.find(aEvent => aEvent.id === nextProps.selectedRowId);
        if (haveSelectedRow) {
            this.setState({ selectedEventId: nextProps.selectedRowId })
        } else {
            this.setState({ selectedEventId: null });
        }
    }


    /**
     * Select the row for the event with the provided id
     * @param {*} selectedEventId 
     */
    selectEventRow(selectedEventId) {
        if (this.state.selectedEventId === selectedEventId) {
            this.setState({ selectedEventId: null, showXML: false });
            this.props.selectRowId(null);
        } else {
            this.setState({ selectedEventId, showXML: false });
            this.props.selectRowId(selectedEventId);
        }
    }


    /**
     * Save event
     * @param {*} event 
     */
    saveEvent(event) {
        this.props.editEvent(event);
    }

    showXML() {
        this.setState({ selectedEventId: null, showXML: !this.state.showXML })
    }

    hideXML() {
        this.setState({ showXML: false })
    }

    eventOrIntervalEditor(event) {
        if (this.state.showXML) {
            return <XMLViewer editorClose={this.hideXML.bind(this)} />
        }
        else if (event && event.intervalKey) {
            const { startEvent, endEvent } = utils.getIntervalEvents(this.props.events, event.intervalKey);
            const intervalEvents = [startEvent, endEvent];
            return <IntervalEditor events={intervalEvents}
                saveEvent={this.saveEvent.bind(this)}
                editorClose={this.selectEventRow.bind(this, null)} />
        }
        else if (event && event.isInsert()) {
            return <InsertEditor event={event}
                saveEvent={this.saveEvent.bind(this)}
                editorClose={this.selectEventRow.bind(this, null)} />
        } else {
            return <EventEditor event={event}
                saveEvent={this.saveEvent.bind(this)}
                editorClose={this.selectEventRow.bind(this, null)} />
        }
    }


    render() {
        const eventsProps = {
            selectEventRow: this.selectEventRow.bind(this),
            selectedEventId: this.state.selectedEventId,
            showXML: this.showXML.bind(this)
        }

        const event = (this.props.events || []).find(aEvent => aEvent.id === this.state.selectedEventId);
        const showEditorClass = this.state.selectedEventId || this.state.showXML ? 'showEditor' : '';
        return (
            <div className={`eventsWrapper ${showEditorClass}`}>
                <Events {...eventsProps} />
                {this.eventOrIntervalEditor(event)}
            </div>
        );
    }
}

EventsWrapper.propTypes = {
    selectedRowId: PropTypes.string,
    selectRowId: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    editEvent: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    selectedRowId: state.session.selectedRowId,
    events: state.events
});
const mapDispatchToProps = { editEvent, selectRowId };
export default connect(mapStateToProps, mapDispatchToProps)(EventsWrapper);