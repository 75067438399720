import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setSessionId, setFileName, incrementUploads, resetUploads, uploadSuccess, uploadFailure, setInfo } from '../store/session/actions';
import { saveMetadata, fetchConfigurationAndMetadata, fetchEventsGeneratedFromXML } from '../store/session/api';
import axios from 'axios';
import Labels from '../assets/labels.json';
import TextInput from './TextInput';
import LoadingWave from './LoadingWave';

const CAPTCHA_SITE_KEY = "6LeGZooUAAAAALfOHEO5HXaS7Pze3PixKeWQ6uRf";
const CAPTCHA_DISPLAY_INTERVAL = 180000; //3 minutes in miliseconds
const CAPTCHA_UPLOAD_LIMIT = 3;

const MAX_FILE_SIZE_IN_BYTES = 150 * 1024 * 1024;
const MAX_FILE_NAME_LENGTH = 128;



/**
 * Renders a wrapper over DropZone component
 * 
 * Usage:
 * ```js
 * <DropZoneWrapper />
* ```
*/
export class DropZoneWrapper extends React.Component {

    source;

    constructor(props) {
        super(props);
        const url = this.extractUriFromQuery(this.props.location.search) || '';
        this.state = { msg: '', url: url, uploading: false, redirect: '', percent: 0, file: {}, readFromUrl: false, readFromRssFeed: false }
    }


    componentDidMount() {
        if (this.state.url) {
            this.setState({ readFromUrl: true });
            this.loadUrl();
        }
    }


    /**
     * Handles a successful file upload 
     * @param {*} data 
     * @param {*} fileToUpload 
     */
    onFileUploaded(data, fileToUpload = {}) {
        if (data) {
            const sessionId = data;
            this.props.setSessionId(sessionId);
            this.props.setFileName(fileToUpload.name);

            this.props.fetchConfigurationAndMetadata(sessionId).then(confResult => {
                // generate events from xml
                this.props.fetchEventsGeneratedFromXML(sessionId).then(xmlEventsResult => {
                    if (xmlEventsResult && xmlEventsResult.length > 0) {
                        const eventLabels = xmlEventsResult.map(aEvent => aEvent.label).join(', ');
                        let message = '';
                        if (xmlEventsResult.length > 1) {
                            message = `${eventLabels} events were automatically generated based on the XML`
                        } else {
                            message = `${eventLabels} event was automatically generated based on the XML`
                        }

                        this.props.setInfo(message);
                    }

                    this.props.saveMetadata(sessionId).then(saveResult => {
                        this.setState({ msg: '', uploading: false, redirect: sessionId });
                        if (this.props.uploads >= CAPTCHA_UPLOAD_LIMIT) {
                            this.props.resetUploads();
                        }
                        this.props.incrementUploads();
                    })
                }).catch(err => {
                    this.setState({ msg: '', uploading: false, percent: 0, file: {} });
                });
            })
        }
        else {
            this.setState({ msg: `Failed to process the file '${fileToUpload.name || 'invalid file'}'. Try again?`, uploading: false, percent: 0, file: {} });
        }
    }


    extractUriFromQuery(uri) {
        return decodeURIComponent(uri)
            .replace(/"/g, '')
            .split('?url=')[1]
    }


    /**
     * Handles the errors of a file upload
     * @param {*} error 
     * @param {*} fileToUpload 
     */
    onFileUploadError(error = {}, fileToUpload = {}) {
        // request cancelled
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
            this.setState({ msg: '', uploading: false, percent: 0, file: {} });
        } else {
            console.error('Error:', error);
            this.setState({ msg: `Failed to upload '${fileToUpload.name || 'invalid file'}'. Try again?`, uploading: false, percent: 0, file: {} });

            const errorMessage = (error.response && error.response.data) || error.message;
            this.props.uploadFailure(`Failed to upload '${fileToUpload.name || 'invalid file'}'. Reason: ${errorMessage}. Try again?`);
        }
    }


    /**
     * Handles the selection of the files
     * @param {array} files The accepted files
     * @param {array} rejections The rejected files
     */
    onDrop(files, rejections) {
        // if we have a single accepted file
        if (files && files.length === 1) {
            const data = new FormData();
            const fileToUpload = files[0];

            const fileName = fileToUpload.name;
            if (fileName.length > MAX_FILE_NAME_LENGTH) {
                this.onFileUploadError({ message: 'File name too long' }, fileToUpload);
                return;
            }

            data.append('file', fileToUpload);

            this.setState({ uploading: true, msg: '', file: { name: fileToUpload.name, size: fileToUpload.size } });

            // keep the source around in case we want to cancel the upload
            this.source = axios.CancelToken.source();
            const config = {
                cancelToken: this.source.token,
                onUploadProgress: progressEvent => {
                    const size = this.state.file.size;
                    const uploaded = progressEvent.loaded;
                    const percent = Math.min(Math.round(100 * uploaded / size), 100);
                    this.setState({ percent: percent });
                }
            }

            // call server to upload the file
            axios.post('/api/file', data, config)
                .then((response) => {
                    this.props.uploadSuccess(fileToUpload.name);
                    this.onFileUploaded(response.data.data, fileToUpload);
                }).catch(error => {
                    this.onFileUploadError(error, fileToUpload);
                });
        }
        else {
            // we have only rejected files, need to show an error
            const size = rejections[0] && rejections[0].size;
            const isTooBig = (size || 0) > MAX_FILE_SIZE_IN_BYTES;
            const fileToUpload = rejections[0] || { 'name': 'file' };
            this.onFileUploadError({ message: isTooBig ? Labels.fileTooLarge : Labels.invalidFile }, fileToUpload);
        }
    }


    onReadFromUrl(event) {
        event.stopPropagation();
        this.setState({
            readFromUrl: true,
            readFromRssFeed: false
        })
    }


    onReadFromRssFeed(event) {
        event.stopPropagation();
        this.setState({
            readFromUrl: true,
            readFromRssFeed: true
        })
    }


    /**
     * Cancel upload
     */
    onCancel() {
        this.source.cancel('Operation canceled by the user.');
    }


    onCancelUrl() {
        this.setState({
            readFromUrl: false
        })
    }


    isUrlValid(url) {
        return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(url);
    }


    loadUrl() {
        if (this.state.readFromRssFeed) {
            const history = this.props.history;
            const url = encodeURIComponent(this.state.url);
            history.push(`/rssfeed/${url}`);
        } else {
            this.setState({ uploading: true });
            //call server to upload the file
            axios.post('/api/file/url', { url: this.state.url })
                .then((response) => {
                    const fileToUpload = {};
                    const fileName = response.data.name;
                    const fileSize = response.data.filesize;

                    fileToUpload.name = fileName;
                    fileToUpload.size = fileSize;

                    this.props.uploadSuccess(fileToUpload.name);
                    this.onFileUploaded(response.data.data, fileToUpload)
                }).catch(error => {
                    this.setState({ uploading: false });
                    this.onFileUploadError(error, this.state.url);
                });
        }
    }

    onCaptchaResponse() {
        this.props.resetUploads();
    }

    renderDropzone() {
        return (
            <Dropzone
                className={`dropZone ${this.state.file && this.state.file.name ? 'hidden' : ''}  `}
                maxSize={MAX_FILE_SIZE_IN_BYTES}
                accept="audio/mp3, audio/mpeg, audio/MPA, audio/mpa-robust, audio/mp4, audio/m4a, audio/x-m4a, video/mp4" multiple={false} onDrop={this.onDrop.bind(this)} >
                <span>Drag file or <button>browse</button> or <button onClick={this.onReadFromUrl.bind(this)}>read from URL</button> or <button onClick={this.onReadFromRssFeed.bind(this)}>read from RSS Feed</button></span>
            </Dropzone>
        );
    }

    renderCaptcha() {
        return (
            <ReCAPTCHA
                className="captchaWrapper"
                sitekey={CAPTCHA_SITE_KEY}
                onChange={this.onCaptchaResponse.bind(this)} />
        );
    }

    displayCaptcha() {
        let uploads = [];
        if (this.props.time && this.props.time.includes(',')) {
            uploads = this.props.time.split(',');
        }

        if (uploads.length === CAPTCHA_UPLOAD_LIMIT) {
            const firstUpload = Number(uploads[0]);
            const lastUpload = Number(uploads[2]);
            return lastUpload - firstUpload <= CAPTCHA_DISPLAY_INTERVAL;
        }
        return false;
    }

    render() {
        const urlProps = {
            maxlength: '1024',
            className: 'trackingUrlItem',
            id: "url",
            text: this.state.url,
            autoFocus: false,
            onTextChange: (event) => {
                this.setState({
                    url: event.target.value
                })
            }
        };

        return (
            !this.state.redirect ? (
                <section className="dropzoneWrapper">
                    <h2>{Labels.uploadAudioTitle}</h2>
                    <div className={`${this.state.readFromUrl ? 'hidden' : ''} `}>
                        {
                            this.displayCaptcha()
                                ? this.renderCaptcha()
                                : this.renderDropzone()

                        }
                        <div className={`progress ${this.state.file && this.state.file.name ? '' : 'hidden'}  `}>
                            <div className="percent">
                                <div>{this.state.file.name}</div>
                                <div>{this.state.percent}%</div>
                            </div>
                            <div className="progressBar">
                                <div style={{ width: this.state.percent + '%' }}></div>
                            </div>
                            <button onClick={this.onCancel.bind(this)}
                                disabled={!this.state.uploading}>
                                {Labels.cancelButton}
                            </button>
                        </div>
                    </div>


                    <div className={`readFromUrl ${!this.state.readFromUrl ? 'hidden' : ''} `}>
                        <div className={`readFromUrlControls ${this.state.uploading ? 'hidden' : ''}`}>
                            <TextInput {...urlProps} />
                            <button className='primary' title={Labels.loadButton}
                                onClick={this.loadUrl.bind(this)}
                                disabled={!this.isUrlValid(this.state.url)}>
                                {this.state.readFromRssFeed ? 'Read the RSS Feed' : 'Read from URL'}
                            </button>
                            <button className="secondary" onClick={this.onCancelUrl.bind(this)}>
                                {Labels.cancelButton}
                            </button>
                        </div>
                        <div className={`progress ${!this.state.uploading ? 'hidden' : ''}`}>
                            <LoadingWave loadingMessage={`${this.state.url}`} />
                        </div>
                    </div>

                    <div className='howItWorks'>
                        <h3>{Labels.howToUse}</h3>
                        <ul>
                            <ol><span>1</span>{Labels.howToUseStep1}</ol>
                            <ol><span>2</span>{Labels.howToUseStep2}</ol>
                            <ol><span>3</span>{Labels.howToUseStep3}</ol>
                        </ul>
                    </div>

                    <div className="legal">
                        <a href="https://www.npr.org/about-npr/179876898/terms-of-use" target="_blank" rel="noopener noreferrer">terms of use</a>
                        <a href="https://www.npr.org/about-npr/179878450/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</a>
                        <a href="https://rad.npr.org/" target="_blank" rel="noopener noreferrer">about rad</a>
                        <a href="https://n.pr/radeditor" target="_blank" rel="noopener noreferrer">give feedback</a>
                        <span>© {(new Date().getFullYear())} npr</span>
                    </div>
                </section >
            ) : <Redirect push to={`/${this.state.redirect}`} />
        );
    }
}

DropZoneWrapper.propTypes = {
    setSessionId: PropTypes.func.isRequired,
    setFileName: PropTypes.func.isRequired,
    uploadFailure: PropTypes.func.isRequired,
    uploadSuccess: PropTypes.func.isRequired,
    saveMetadata: PropTypes.func.isRequired,
    setInfo: PropTypes.func.isRequired,
    fetchConfigurationAndMetadata: PropTypes.func.isRequired,
    fetchEventsGeneratedFromXML: PropTypes.func.isRequired,
    incrementUploads: PropTypes.func.isRequired,
    resetUploads: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    uploads: state.session.uploads,
    time: state.session.time
});

const mapDispatchToProps = {
    setSessionId,
    setFileName,
    uploadFailure,
    uploadSuccess,
    saveMetadata,
    fetchConfigurationAndMetadata,
    fetchEventsGeneratedFromXML,
    incrementUploads,
    resetUploads,
    setInfo
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropZoneWrapper));