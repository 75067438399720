
/**
 * Prevents mutations on the object
 * @param {*} o 
 * @see https://github.com/substack/deep-freeze/blob/master/index.js
 */
function deepFreeze(o) {
    if (o) {
        Object.freeze(o);

        Object.getOwnPropertyNames(o).forEach((prop) => {
            if (o.hasOwnProperty(prop)
                && o[prop] !== null
                && (typeof o[prop] === "object" || typeof o[prop] === "function")
                && !Object.isFrozen(o[prop])) {
                deepFreeze(o[prop]);
            }
        });
    }

    return o;
};

export default deepFreeze;