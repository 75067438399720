import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { clearErrors, uploadSuccess, uploadFailure } from '../store/session/actions';
import axios from 'axios';
import Labels from '../assets/labels.json';

const MAX_CSV_FILE_SIZE_IN_BYTES = 1 * 1024 * 1024;
const MAX_FILE_NAME_LENGTH = 128;

/**
 * Renders a wrapper over DropZone component to upload the metadata for a file
 * 
 * Usage:
 * ```js
 * <MetadataUpload />
 * ```
 */
export class MetadataUpload extends React.Component {

  source;
  defaultTemplate = `
podcastID=123456
episodeID=987654321
trackingUrls=https://rad.site.com,trackingUrls=https://rad.company.org,
eventTime=00:00:00.000,label=Testing1,label1=podcastDownload,adId=0,creativeId=0,adPosition=0,eventNum=0,
eventTime=00:00:05.000,label=Testing2,label4=adEnd,adId=111128,creativeId=1111132,adPosition=1,eventNum=3,
eventTime=00:00:05.000,label=Testing3,label2=podcaststart,adId=0,creativeId=0,adPosition=0,eventNum=1,
eventTime=00:00:05.000,label=Testing4,label3=adStart,adId=111128,creativeId=1111132,adPosition=1,test=testString,eventNum=2,
    `

  constructor() {
    super();
    this.state = {
      uploading: false,
      redirect: '',
      percent: 0,
      file: {},
      replaceExistingMetadata: true,
      templateContent: this.defaultTemplate
    }
  }


  componentDidMount() {
    axios.get(`/template.csv`)
      .then((response) => {
        const content = response.data;
        if (content) {
          this.setState({ templateContent: content })
        }
      }).catch(error => {        
      });
  }

  /**
   * Handles a successful file upload 
   */
  onFileUploaded(data, fileToUpload) {
    this.props.uploadSuccess(fileToUpload.name);
    this.setState({ uploading: false, redirect: this.props.sessionId });
  }


  /**
   * Handles the errors of a file upload
   * @param {*} error 
   * @param {*} fileToUpload 
   */
  onFileUploadError(error = {}, fileToUpload = {}) {
    // request cancelled
    if (axios.isCancel(error)) {
      this.setState({ uploading: false, percent: 0, file: {} });
    } else {
      this.setState({ uploading: false, redirect: '', percent: 0, file: {} });
      const errorMessage = (error.response && error.response.data) || error.message;
      this.props.uploadFailure(`Failed to upload '${fileToUpload.name}'. Reason: ${errorMessage}. Try again?`);
    }
  }


  /**
   * Handles the selection of the files
   * @param {array} files The accepted files
   * @param {array} rejections The rejected files
   */
  onDrop(files, rejections) {
    this.props.clearErrors();

    if (files && files.length === 1) {
      const data = new FormData();
      const fileToUpload = files[0];

      const fileName = fileToUpload.name;
      if (fileName.length > MAX_FILE_NAME_LENGTH) {
        this.onFileUploadError({ message: 'File name too long' }, fileToUpload);
        return;
      }

      data.append('file', fileToUpload);
      this.setState({ uploading: true, msg: '', file: { name: fileToUpload.name, size: fileToUpload.size } });

      this.source = axios.CancelToken.source();
      const config = {
        headers: { 'Authorization': 'Key ' + this.props.sessionId },
        cancelToken: this.source.token,
        onUploadProgress: progressEvent => {
          const size = this.state.file.size;
          const uploaded = progressEvent.loaded;
          const percent = Math.min(Math.round(100 * uploaded / size), 100);
          this.setState({ percent: percent });
        }
      }

      axios.post(`/api/metadata?replace=${this.state.replaceExistingMetadata}`, data, config)
        .then((response) => {
          this.onFileUploaded(response.data, fileToUpload)
        }).catch(error => {
          this.onFileUploadError(error, fileToUpload);
        });
    }
    else {
      // we have only rejected files, need to show an error
      const size = rejections[0] && rejections[0].size;
      const isTooBig = (size || 0) > MAX_CSV_FILE_SIZE_IN_BYTES;
      const fileToUpload = rejections[0] || { 'name': 'file' };
      this.onFileUploadError({ message: isTooBig ? Labels.fileTooLarge : Labels.invalidFile }, fileToUpload);
    }
  }


  /**
   * Replace existing metadata
   */
  replaceExistingMetadata() {
    const current = this.state.replaceExistingMetadata;
    this.setState({ replaceExistingMetadata: !current });
  }


  /**
   * Handle upload cancel
   */
  onCancel() {
    this.source.cancel('Operation canceled by the user.');
  }


  render() {
    return (
      !this.state.redirect ? (
        <section className='dropzoneWrapper'>

          <h2>
            {Labels.uploadMetadataTitle}
          </h2>

          {this.state.replaceExistingMetadata ?
            (<aside className='warning'>
              <span>Warning!</span> {Labels.uploadMetadataWarning} <em>{this.props.fileName}</em>.&nbsp;
            <a href='#' onClick={() => this.props.history.goBack()}>{Labels.uploadMetadataCancelText}</a>
            </aside>) : ''}

          <div>
            <Dropzone
              className={`dropZone ${this.state.file && this.state.file.name ? 'hidden' : ''}  `}
              maxSize={MAX_CSV_FILE_SIZE_IN_BYTES}
              accept='text/csv' multiple={false} onDrop={this.onDrop.bind(this)} >
              <span>Drag file or <button>browse</button></span>
            </Dropzone>
            <div className={`progress ${this.state.file && this.state.file.name ? '' : 'hidden'}  `}>
              <div className='percent'>
                <div>{this.state.file.name}</div>
                <div>{this.state.percent}%</div>
              </div>
              <div className='progressBar'>
                <div style={{ width: this.state.percent + '%' }}></div>
              </div>
              <button onClick={this.onCancel.bind(this)} disabled={!this.state.uploading}>{Labels.cancelButton}</button>
            </div>
          </div>
          <div className='csvInfo'>
            <div>
              If you prefer to add your Remote Audio Data tags using a .cvs file, please follow the following guidelines:
            <ul>
                <li>One .cvs file per MP3 / MP4 file.</li>
                <li>Please use <a href='/template.csv'>this attached template</a>, where metadata is stored in the .csv as key-value pairs, ex: podcastId=510313</li>
                <li>If the event contains custom metadata, that custom metadata must be added in the next cells, ex: columns D-H in the template file.</li>
                <li>The tags added via this .csv will overwrite any exiting RAD ID3 tags on the media file.</li>
              </ul>
            </div>

            <div className='csvExample'>
              Example:
              <pre>
                {this.state.templateContent}
              </pre>
            </div>
          </div>
        </section>
      ) : <Redirect push to={`/${this.state.redirect}`} />
    );
  }
}


MetadataUpload.propTypes = {
  sessionId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired,
  uploadFailure: PropTypes.func.isRequired,
  uploadSuccess: PropTypes.func.isRequired,
}

const mapStateToProps = state => state.session;
const mapDispatchToProps = { clearErrors, uploadFailure, uploadSuccess };
export default connect(mapStateToProps, mapDispatchToProps)(MetadataUpload);