import {
    SET_TRACKING_URLS,
    ADD_TRACKING_URL,
    EDIT_TRACKING_URL,
    DELETE_TRACKING_URL
} from './types';
import deepFreeze from '../deepFreeze';


export default (state = [], action) => {
    let newState;
    switch (action.type) {
        case SET_TRACKING_URLS:
            newState = [...action.urls];
            deepFreeze(newState);
            return newState;
        case ADD_TRACKING_URL:
            newState = [...state, action.url];
            deepFreeze(newState);
            return newState;
        case EDIT_TRACKING_URL:
            const index = state.findIndex(aUrl => aUrl.id === action.url.id);
            newState = [
                ...state.slice(0, index),
                action.url,
                ...state.slice(index + 1)
            ];
            deepFreeze(newState);
            return newState;
        case DELETE_TRACKING_URL:
            newState = state.filter(aUrl => aUrl.id !== action.url.id)
            deepFreeze(newState);
            return newState;
        default:
            return state;
    }
};