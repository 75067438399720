import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

/**
 * Renders a text input  
 * 
 * Usage:
 * ```js
 * <TextInput />
 * ```
 */
const TextInput = (props) => (
    <label className={props.isValid && !props.isValid() ? props.className + ' invalid' : props.className}>{props.label || ''}
        <input type="text"
            data-for={props.id}
            data-tip
            className='ti'
            autoFocus={props.autoFocus}
            name={props.name}
            maxLength={props.maxlength}
            value={props.text}
            onChange={props.onTextChange} />

        <ReactTooltip
            id={props.id}
            type='error'
            html={true}
            delayShow={1000}
            effect='solid'
            className='tooltip'
            getContent={(dataTip) => props.isValid && !props.isValid() ? props.invalidMessage : ''} />
    </label>
);


TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    isValid: PropTypes.func,
    autoFocus: PropTypes.bool,
    name: PropTypes.string,
    maxlength: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    onTextChange: PropTypes.func.isRequired
}

export default TextInput;